"use strict";
import { BACKEND_SUPPORTED_CHAINS, chainIdToBackendChain } from "constants/chains";
import { ARB, NATIVE_CHAIN_ID, WRAPPED_NATIVE_CURRENCY } from "constants/tokens";
import { useMemo } from "react";
import invariant from "tiny-invariant";
import {
  Chain,
  useSearchTokensWebQuery
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
const ARB_ADDRESS = ARB.address.toLowerCase();
function dedupeCrosschainTokens(current, existing, searchChain) {
  if (!existing) {
    return current;
  }
  invariant(current.project?.id === existing.project?.id, "Cannot dedupe tokens within different tokenProjects");
  if (current.address?.toLowerCase() === ARB_ADDRESS) {
    return current;
  }
  if (existing.address?.toLowerCase() === ARB_ADDRESS) {
    return existing;
  }
  if (current.standard === NATIVE_CHAIN_ID && (existing.standard !== NATIVE_CHAIN_ID || current.chain === searchChain)) {
    return current;
  }
  if (current.chain === searchChain || existing.chain !== searchChain && current.chain === Chain.Ethereum) {
    return current;
  }
  return existing;
}
function searchTokenSortFunction(searchChain, wrappedNativeAddress, a, b) {
  if (a.standard === NATIVE_CHAIN_ID) {
    if (b.standard === NATIVE_CHAIN_ID) {
      if (a.chain === searchChain) {
        return -1;
      } else if (b.chain === searchChain) {
        return 1;
      } else {
        return 0;
      }
    } else {
      return -1;
    }
  } else if (b.standard === NATIVE_CHAIN_ID) {
    return 1;
  } else if (wrappedNativeAddress && a.address === wrappedNativeAddress) {
    return -1;
  } else if (wrappedNativeAddress && b.address === wrappedNativeAddress) {
    return 1;
  } else {
    return (b.market?.volume24H?.value ?? 0) - (a.market?.volume24H?.value ?? 0);
  }
}
export function useSearchTokens(searchQuery, chainId) {
  const { data, loading, error } = useSearchTokensWebQuery({
    variables: {
      searchQuery: searchQuery ?? ""
    },
    skip: !searchQuery
  });
  const sortedTokens = useMemo(() => {
    const searchChain = chainIdToBackendChain({ chainId, withFallback: true });
    const selectionMap = {};
    const filteredTokens = data?.searchTokens?.filter(
      (token) => token !== void 0 && BACKEND_SUPPORTED_CHAINS.includes(token.chain)
    );
    filteredTokens?.forEach((token) => {
      if (token.project?.id) {
        const existing = selectionMap[token.project.id];
        selectionMap[token.project.id] = dedupeCrosschainTokens(token, existing, searchChain);
      }
    });
    return Object.values(selectionMap).sort(
      searchTokenSortFunction.bind(null, searchChain, WRAPPED_NATIVE_CURRENCY[chainId]?.address)
    );
  }, [data, chainId]);
  return {
    data: sortedTokens,
    loading,
    error
  };
}
