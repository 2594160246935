"use strict";
export const SUPPORTED_LOCALES = [
  // order as they appear in the language dropdown
  "en-US"
];
export const DEFAULT_LOCALE = "en-US";
export const LOCALE_LABEL = {
  "af-ZA": "Afrikaans",
  "ar-SA": "\u0627\u0644\u0639\u0631\u0628\u064A\u0629",
  "ca-ES": "Catal\xE0",
  "cs-CZ": "\u010De\u0161tina",
  "da-DK": "dansk",
  "el-GR": "\u03B5\u03BB\u03BB\u03B7\u03BD\u03B9\u03BA\u03AC",
  "en-US": "English",
  "es-ES": "Espa\xF1ol",
  "fi-FI": "suomi",
  "fr-FR": "fran\xE7ais",
  "he-IL": "\u05E2\u05B4\u05D1\u05E8\u05B4\u05D9\u05EA",
  "hu-HU": "Magyar",
  "id-ID": "bahasa Indonesia",
  "it-IT": "Italiano",
  "ja-JP": "\u65E5\u672C\u8A9E",
  "ko-KR": "\uD55C\uAD6D\uC5B4",
  "nl-NL": "Nederlands",
  "no-NO": "norsk",
  "pl-PL": "Polskie",
  "pt-BR": "portugu\xEAs",
  "pt-PT": "portugu\xEAs",
  "ro-RO": "Rom\xE2n\u0103",
  "ru-RU": "\u0440\u0443\u0441\u0441\u043A\u0438\u0439",
  "sr-SP": "\u0421\u0440\u043F\u0441\u043A\u0438",
  "sv-SE": "svenska",
  "sw-TZ": "Kiswahili",
  "tr-TR": "T\xFCrk\xE7e",
  "uk-UA": "\u0423\u043A\u0440\u0430\u0457\u043D\u0441\u044C\u043A\u0438\u0439",
  "vi-VN": "Ti\u1EBFng Vi\u1EC7t",
  "zh-CN": "\u7B80\u4F53\u4E2D\u6587",
  "zh-TW": "\u7E41\u4F53\u4E2D\u6587"
};
