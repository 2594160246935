"use strict";
import { CurrencyAmount } from "@uniswap/sdk-core";
import { useTokenContract } from "hooks/useContract";
import { useSingleCallResult } from "lib/hooks/multicall";
import { useMemo } from "react";
export function useTotalSupply(token) {
  const contract = useTokenContract(token?.isToken ? token.address : void 0, false);
  const totalSupplyStr = useSingleCallResult(contract, "totalSupply")?.result?.[0]?.toString();
  return useMemo(
    () => token?.isToken && totalSupplyStr ? CurrencyAmount.fromRawAmount(token, totalSupplyStr) : void 0,
    [token, totalSupplyStr]
  );
}
