import { createContext, Fragment, useContext } from "react";
import {
  ButtonText,
  getTokenValue,
  spacedChildren,
  styled,
  Text,
  useGetThemedIcon,
  useProps,
  withStaticProperties,
  wrapChildrenInText,
  XStack
} from "tamagui";
import { useHapticFeedback } from "ui/src/utils/haptics/useHapticFeedback";
const ButtonNestingContext = createContext(false);
const CustomButtonFrame = styled(XStack, {
  name: "Button",
  tag: "button",
  // instead of setting border: 0 when no border, make it 1px but transparent, so the
  // size or alignment of a button won't change unexpectedly between variants
  borderWidth: 1,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "$background",
  borderColor: "$borderColor",
  cursor: "pointer",
  height: "auto",
  hoverStyle: {
    backgroundColor: "$backgroundHover"
  },
  pressStyle: {
    backgroundColor: "$backgroundPress",
    opacity: 0.7
  },
  variants: {
    size: {
      small: {
        padding: "$spacing8",
        borderRadius: "$rounded12",
        gap: "$spacing4"
      },
      medium: {
        padding: "$spacing12",
        borderRadius: "$rounded16",
        gap: "$spacing8"
      },
      large: {
        padding: "$spacing16",
        paddingVertical: "$spacing16",
        borderRadius: "$rounded20",
        gap: "$spacing12"
      }
    },
    fill: {
      true: {
        flex: 1
      }
    },
    backgroundless: {
      true: {
        backgroundColor: "transparent",
        hoverStyle: {
          backgroundColor: "transparent",
          opacity: 0.9
        },
        pressStyle: {
          backgroundColor: "transparent",
          opacity: 0.7
        }
      }
    },
    disabled: {
      true: {
        opacity: 0.4,
        pointerEvents: "none",
        userSelect: "none"
      }
    },
    fadeIn: {
      true: {
        enterStyle: {
          opacity: 0
        }
      }
    },
    fadeOut: {
      true: {
        enterStyle: {
          opacity: 0
        }
      }
    }
  },
  defaultVariants: {
    size: "medium"
  }
});
const CustomButtonText = styled(Text, {
  tag: "span",
  fontFamily: "$button",
  color: "$color",
  cursor: "pointer",
  variants: {
    size: {
      micro: {
        fontSize: "$micro",
        fontWeight: "$medium",
        lineHeight: "$micro"
      },
      medium: {
        fontSize: "$medium",
        fontWeight: "$medium",
        lineHeight: "$medium"
      },
      small: {
        fontSize: "$small",
        fontWeight: "$medium",
        lineHeight: "$small"
      },
      large: {
        fontSize: "$large",
        fontWeight: "$medium",
        lineHeight: "$large"
      }
    }
  },
  defaultVariants: {
    size: "medium"
  }
});
const ButtonComponent = CustomButtonFrame.styleable((props, ref) => {
  const { props: buttonProps } = useButton(props);
  const { hapticFeedback } = useHapticFeedback();
  return <CustomButtonFrame
    ref={ref}
    onPressIn={buttonProps.hapticFeedback ? () => {
      void hapticFeedback.impact(buttonProps.hapticStyle);
    } : void 0}
    {...buttonProps}
  />;
});
ButtonComponent.defaultProps = {
  theme: "primary"
};
export const Button = withStaticProperties(ButtonComponent, {
  Text: ButtonText
});
const buttonToIconSize = {
  small: "$icon.12",
  medium: "$icon.20",
  large: "$icon.24"
};
function useButton(propsIn) {
  const {
    // not button frame props
    icon,
    iconAfter,
    separator,
    color,
    ...buttonFrameProps
  } = propsIn;
  const isNested = useContext(ButtonNestingContext);
  const propsActive = useProps(propsIn);
  const size = propsActive.size || "medium";
  const iconSize = getTokenValue(buttonToIconSize[size]);
  const getThemedIcon = useGetThemedIcon({ size: iconSize, color });
  const [themedIcon, themedIconAfter] = [icon, iconAfter].map(getThemedIcon);
  const contents = wrapChildrenInText(
    CustomButtonText,
    // @ts-expect-error the props are alright
    propsActive,
    {
      size,
      disabled: propsActive.disabled,
      maxFontSizeMultiplier: 1.2
    }
  );
  const inner = spacedChildren({
    separator,
    direction: propsActive.flexDirection === "column" || propsActive.flexDirection === "column-reverse" ? "vertical" : "horizontal",
    children: [<Fragment key="icon">{themedIcon}</Fragment>, ...contents, themedIconAfter]
  });
  const tag = isNested ? "span" : (
    // defaults to <a /> when accessibilityRole = link
    // see https://github.com/tamagui/tamagui/issues/505
    propsIn.accessibilityRole === "link" ? "a" : void 0
  );
  const props = {
    ...propsActive.disabled && {
      // in rnw - false still has keyboard tabIndex, undefined = not actually focusable
      focusable: void 0,
      // even with tabIndex unset, it will keep focusStyle on web so disable it here
      focusStyle: {
        borderColor: "$background"
      }
    },
    ...tag && {
      tag
    },
    ...buttonFrameProps,
    children: <ButtonNestingContext.Provider value={true}>{inner}</ButtonNestingContext.Provider>
  };
  return {
    isNested,
    props
  };
}
