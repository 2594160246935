"use strict";
import { SwapEventName, SwapPriceUpdateUserResponse } from "@uniswap/analytics-events";
import { AutoColumn } from "components/Column";
import SwapError, { PendingModalError } from "components/ConfirmSwapModal/Error";
import { SwapHead } from "components/ConfirmSwapModal/Head";
import { SwapModal } from "components/ConfirmSwapModal/Modal";
import { Pending } from "components/ConfirmSwapModal/Pending";
import SwapProgressIndicator from "components/ConfirmSwapModal/ProgressIndicator";
import { MODAL_TRANSITION_DURATION } from "components/Modal";
import { SwapDetails } from "components/swap/SwapDetails";
import { SwapPreview } from "components/swap/SwapPreview";
import { useConfirmModalState } from "hooks/useConfirmModalState";
import { AllowanceState } from "hooks/usePermit2Allowance";
import styled from "lib/styled-components";
import { useCallback, useEffect, useMemo } from "react";
import { useSuppressPopups } from "state/application/hooks";
import { PopupType } from "state/application/reducer";
import { isLimitTrade, isPreviewTrade, isUniswapXTradeType } from "state/routing/utils";
import { useOrder } from "state/signatures/hooks";
import { useSwapTransactionStatus } from "state/transactions/hooks";
import { ThemeProvider } from "theme";
import { FadePresence } from "theme/components/FadePresence";
import { UniswapXOrderStatus } from "types/uniswapx";
import { TransactionStatus } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
import { SignatureExpiredError, UniswapXv2HardQuoteError } from "utils/errors";
import { formatSwapPriceUpdatedEventProperties } from "utils/loggingFormatters";
import { didUserReject } from "utils/swapErrorToUserReadableMessage";
const Container = styled.div`
  height: ${({ $height }) => $height ?? ""};
  padding: ${({ $padding }) => $padding ?? ""};
`;
export var ConfirmModalState = /* @__PURE__ */ ((ConfirmModalState2) => {
  ConfirmModalState2[ConfirmModalState2["REVIEWING"] = 0] = "REVIEWING";
  ConfirmModalState2[ConfirmModalState2["WRAPPING"] = 1] = "WRAPPING";
  ConfirmModalState2[ConfirmModalState2["RESETTING_TOKEN_ALLOWANCE"] = 2] = "RESETTING_TOKEN_ALLOWANCE";
  ConfirmModalState2[ConfirmModalState2["APPROVING_TOKEN"] = 3] = "APPROVING_TOKEN";
  ConfirmModalState2[ConfirmModalState2["PERMITTING"] = 4] = "PERMITTING";
  ConfirmModalState2[ConfirmModalState2["PENDING_CONFIRMATION"] = 5] = "PENDING_CONFIRMATION";
  return ConfirmModalState2;
})(ConfirmModalState || {});
export function ConfirmSwapModal({
  trade,
  originalTrade,
  inputCurrency,
  selectedPool,
  allowance,
  allowedSlippage,
  fiatValueInput,
  fiatValueOutput,
  swapResult,
  swapError,
  priceImpact,
  clearSwapState,
  onAcceptChanges,
  onConfirm,
  onCurrencySelection,
  onDismiss,
  onXV2RetryWithClassic
}) {
  const {
    confirmModalState,
    pendingModalSteps,
    priceUpdate,
    doesTradeDiffer,
    approvalError,
    tokenError,
    wrapTxHash,
    startSwapFlow,
    onCancel,
    resetToReviewScreen
  } = useConfirmModalState({
    trade,
    originalTrade,
    allowance,
    allowedSlippage,
    selectedPool,
    onCurrencySelection,
    onSwap: () => {
      clearSwapState();
      onConfirm();
    }
  });
  const swapStatus = useSwapTransactionStatus(swapResult);
  const uniswapXOrder = useOrder(isUniswapXTradeType(swapResult?.type) ? swapResult.response.orderHash : "");
  const swapConfirmed = swapStatus === TransactionStatus.Confirmed || uniswapXOrder?.status === UniswapXOrderStatus.FILLED;
  const limitPlaced = isLimitTrade(trade) && uniswapXOrder?.status === UniswapXOrderStatus.OPEN;
  const localSwapFailure = Boolean(swapError) && !didUserReject(swapError);
  const swapReverted = swapStatus === TransactionStatus.Failed;
  const swapFailed = localSwapFailure || swapReverted;
  const errorType = useMemo(() => {
    if (approvalError) {
      return approvalError;
    }
    if (tokenError) {
      return tokenError;
    }
    if (swapError instanceof SignatureExpiredError) {
      return;
    }
    if (swapError instanceof UniswapXv2HardQuoteError) {
      return PendingModalError.XV2_HARD_QUOTE_ERROR;
    }
    if (swapError && !didUserReject(swapError)) {
      return PendingModalError.CONFIRMATION_ERROR;
    }
    return;
  }, [approvalError, swapError, tokenError]);
  const { showPreview, showDetails, showProgressIndicator, showAcceptChanges, showConfirming, showSuccess, showError } = useMemo(() => {
    const showAcceptChanges2 = confirmModalState !== 5 /* PENDING_CONFIRMATION */ && doesTradeDiffer;
    let showPreview2, showDetails2, showProgressIndicator2, showConfirming2, showSuccess2, showError2;
    if (errorType) {
      showError2 = true;
    } else if (swapConfirmed || limitPlaced) {
      showSuccess2 = true;
    } else if (confirmModalState === 0 /* REVIEWING */ || showAcceptChanges2) {
      showPreview2 = true;
      showDetails2 = true;
    } else if (pendingModalSteps.length > 1) {
      showPreview2 = true;
      showProgressIndicator2 = true;
    } else {
      showConfirming2 = true;
    }
    return {
      showPreview: showPreview2,
      showDetails: showDetails2,
      showProgressIndicator: showProgressIndicator2,
      showAcceptChanges: showAcceptChanges2,
      showConfirming: showConfirming2,
      showSuccess: showSuccess2,
      showError: showError2
    };
  }, [confirmModalState, doesTradeDiffer, errorType, limitPlaced, pendingModalSteps.length, swapConfirmed]);
  useEffect(() => {
    if (swapError && !swapFailed) {
      onCancel();
    }
  }, [onCancel, swapError, swapFailed]);
  const { suppressPopups, unsuppressPopups } = useSuppressPopups([PopupType.Transaction, PopupType.Order]);
  const onModalDismiss = useCallback(() => {
    if (trade && doesTradeDiffer && confirmModalState !== 5 /* PENDING_CONFIRMATION */) {
      sendAnalyticsEvent(
        SwapEventName.SWAP_PRICE_UPDATE_ACKNOWLEDGED,
        formatSwapPriceUpdatedEventProperties(trade, priceUpdate, SwapPriceUpdateUserResponse.REJECTED)
      );
    }
    onDismiss();
    setTimeout(() => {
      onCancel();
    }, MODAL_TRANSITION_DURATION);
    unsuppressPopups();
  }, [confirmModalState, doesTradeDiffer, onCancel, onDismiss, priceUpdate, unsuppressPopups, trade]);
  return (
    // Wrapping in a new theme provider resets any color extraction overriding on the current page. Swap modal should use default/non-overridden theme.
    <ThemeProvider><SwapModal confirmModalState={confirmModalState} onDismiss={onModalDismiss}>
      {
        /* Head section displays title, help button, close icon */
      }
      <Container $height="24px" $padding="6px 12px 4px 12px"><SwapHead
        onDismiss={onModalDismiss}
        isLimitTrade={isLimitTrade(trade)}
        confirmModalState={confirmModalState}
      /></Container>
      {
        /* Preview section displays input / output currency amounts */
      }
      {showPreview && <Container $padding="12px 12px 0px 12px"><SwapPreview inputCurrency={inputCurrency} trade={trade} allowedSlippage={allowedSlippage} /></Container>}
      {
        /* Details section displays rate, fees, network cost, etc. w/ additional details in drop-down menu .*/
      }
      {showDetails && <Container><FadePresence><AutoColumn gap="md"><SwapDetails
        onConfirm={() => {
          suppressPopups();
          startSwapFlow();
        }}
        trade={trade}
        allowance={allowance}
        swapResult={swapResult}
        allowedSlippage={allowedSlippage}
        isLoading={isPreviewTrade(trade)}
        disabledConfirm={showAcceptChanges || isPreviewTrade(trade) || allowance?.state === AllowanceState.LOADING}
        fiatValueInput={fiatValueInput}
        fiatValueOutput={fiatValueOutput}
        showAcceptChanges={Boolean(showAcceptChanges)}
        onAcceptChanges={onAcceptChanges}
        swapErrorMessage={swapFailed ? swapError?.message : void 0}
        priceImpact={priceImpact}
      /></AutoColumn></FadePresence></Container>}
      {
        /* Progress indicator displays all the steps of the swap flow and their current status  */
      }
      {confirmModalState !== 0 /* REVIEWING */ && showProgressIndicator && <Container><FadePresence><SwapProgressIndicator
        steps={pendingModalSteps}
        currentStep={confirmModalState}
        trade={trade}
        swapResult={swapResult}
        wrapTxHash={wrapTxHash}
        tokenApprovalPending={allowance?.state === AllowanceState.REQUIRED && allowance.isApprovalPending}
        revocationPending={allowance?.state === AllowanceState.REQUIRED && allowance.isRevocationPending}
        swapError={swapError}
        onRetryUniswapXSignature={onConfirm}
      /></FadePresence></Container>}
      {
        /* Pending screen displays spinner for single-step confirmations, as well as success screen for all flows */
      }
      {(showConfirming || showSuccess) && <Container><FadePresence><Pending
        trade={trade}
        swapResult={swapResult}
        wrapTxHash={wrapTxHash}
        tokenApprovalPending={allowance?.state === AllowanceState.REQUIRED && allowance.isApprovalPending}
        revocationPending={allowance?.state === AllowanceState.REQUIRED && allowance.isRevocationPending}
      /></FadePresence></Container>}
      {
        /* Error screen handles all error types with custom messaging and retry logic */
      }
      {errorType && showError && <Container $padding="16px"><SwapError
        trade={trade}
        showTrade={errorType !== PendingModalError.XV2_HARD_QUOTE_ERROR}
        swapResult={swapResult}
        errorType={errorType}
        onRetry={() => {
          if (errorType === PendingModalError.XV2_HARD_QUOTE_ERROR) {
            onXV2RetryWithClassic?.();
            resetToReviewScreen();
          } else {
            startSwapFlow();
          }
        }}
      /></Container>}
    </SwapModal></ThemeProvider>
  );
}
