"use strict";
import { PortfolioLogo } from "components/AccountDrawer/MiniPortfolio/PortfolioLogo";
import { gqlToCurrency, supportedChainIdFromGQLChain } from "graphql/data/util";
import { useMemo } from "react";
import { UniverseChainId } from "uniswap/src/types/chains";
export default function QueryTokenLogo(props) {
  const chainId = (props.token?.chain ? supportedChainIdFromGQLChain(props.token?.chain) : UniverseChainId.Mainnet) ?? UniverseChainId.Mainnet;
  const currency = props.token ? gqlToCurrency(props.token) : void 0;
  const logoUrl = props.token?.project?.logoUrl;
  return <PortfolioLogo currencies={useMemo(() => [currency], [currency])} chainId={chainId} images={[logoUrl]} {...props} />;
}
