"use strict";
import { GrgIcon } from "nft/components/icons";
import styled from "lib/styled-components";
const Container = styled.div`
  position: relative;
  cursor: ${({ clickable }) => clickable ? "pointer" : "auto"};
`;
export const UniIcon = ({
  clickable
  /*, ...props*/
}) => <Container clickable={clickable}><GrgIcon /></Container>;
