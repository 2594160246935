"use strict";
import { useAccount } from "hooks/useAccount";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useActiveSmartPool } from "state/application/hooks";
import { useUnitagByAddress } from "uniswap/src/features/unitags/hooks";
import { shortenAddress } from "utilities/src/addresses";
import { useAccountEffect, useEnsName } from "wagmi";
const recentAccountIdentifierMapAtom = atomWithStorage("recentAccountIdentifierMap", { recent: {} });
export function useAccountIdentifier() {
  const [recentAccountIdentifierMap, updateRecentAccountIdentifierMap] = useAtom(recentAccountIdentifierMapAtom);
  const account = useAccount();
  const activeSmartPool = useActiveSmartPool();
  const { pathname: page } = useLocation();
  const address = activeSmartPool.address && activeSmartPool.address !== "" && page !== "/send" ? activeSmartPool.address : account.address;
  const { unitag: unitagResponse } = useUnitagByAddress(address);
  const { data: ensNameResponse } = useEnsName({ address });
  useAccountEffect({
    onDisconnect() {
      updateRecentAccountIdentifierMap((prev) => ({ ...prev, recent: void 0 }));
    }
  });
  useEffect(() => {
    if (!address) {
      return;
    }
    updateRecentAccountIdentifierMap((prev) => {
      const updatedIdentifiers = prev[address] ?? {};
      if (unitagResponse) {
        updatedIdentifiers.unitag = unitagResponse.username;
      }
      if (ensNameResponse) {
        updatedIdentifiers.ensName = ensNameResponse;
      }
      return { ...prev, [address]: updatedIdentifiers, recent: updatedIdentifiers };
    });
  }, [address, unitagResponse, ensNameResponse, updateRecentAccountIdentifierMap]);
  const { unitag, ensName } = (address ? recentAccountIdentifierMap[address] : recentAccountIdentifierMap["recent"]) ?? {};
  const accountIdentifier = unitag ?? ensName ?? shortenAddress(address);
  return {
    accountIdentifier,
    hasUnitag: Boolean(unitag),
    hasRecent: Boolean(Object.keys(recentAccountIdentifierMap["recent"] || {}).length)
  };
}
