"use strict";
import styled, { keyframes } from "lib/styled-components";
import { darken } from "polished";
import { useState } from "react";
const Wrapper = styled.button`
  align-items: center;
  background: ${({ isActive, theme }) => isActive ? theme.accent2 : "transparent"};
  border: ${({ theme, isActive }) => isActive ? "1px solid transparent" : `1px solid ${theme.surface3}`};
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 4px;
  width: fit-content;
  pointer-events: ${({ $disabled }) => $disabled ? "none" : "auto"};
  ${({ $disabled }) => $disabled && "opacity: 0.6;"}
`;
const turnOnToggle = keyframes`
  from {
    margin-left: 0em;
    margin-right: 2.2em;
  }
  to {
    margin-left: 2.2em;
    margin-right: 0em;
  }
`;
const turnOffToggle = keyframes`
  from {
    margin-left: 2.2em;
    margin-right: 0em;
  }
  to {
    margin-left: 0em;
    margin-right: 2.2em;
  }
`;
const ToggleElementHoverStyle = (hasBgColor, theme, isActive) => hasBgColor ? {
  opacity: "0.8"
} : {
  background: isActive ? darken(0.05, theme.accent1) : darken(0.05, theme.surface3),
  color: isActive ? theme.white : theme.neutral3
};
const ToggleElement = styled.span`
  animation: 0.1s
    ${({ isActive, isInitialToggleLoad }) => isInitialToggleLoad ? "none" : isActive ? turnOnToggle : turnOffToggle}
    ease-in;
  background: ${({ theme, bgColor, isActive }) => isActive ? bgColor ?? theme.accent1 : bgColor ? theme.surface3 : theme.neutral3};
  border-radius: 50%;
  height: 24px;
  :hover {
    ${({ bgColor, theme, isActive }) => ToggleElementHoverStyle(!!bgColor, theme, isActive)}
  }
  margin-left: ${({ isActive }) => isActive && "2.2em"};
  margin-right: ${({ isActive }) => !isActive && "2.2em"};
  width: 24px;
`;
export default function Toggle({ id, bgColor, isActive, disabled, toggle }) {
  const [isInitialToggleLoad, setIsInitialToggleLoad] = useState(true);
  const switchToggle = () => {
    toggle();
    if (isInitialToggleLoad) {
      setIsInitialToggleLoad(false);
    }
  };
  return <Wrapper
    id={id}
    data-testid={id}
    role="option"
    aria-selected={isActive}
    isActive={isActive}
    $disabled={disabled}
    onClick={switchToggle}
  ><ToggleElement isActive={isActive} bgColor={bgColor} isInitialToggleLoad={isInitialToggleLoad} /></Wrapper>;
}
