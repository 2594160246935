"use strict";
import { CustomUserProperties, SwapEventName } from "@uniswap/analytics-events";
import { MulticallExtended, PaymentsExtended, SwapRouter } from "@uniswap/router-sdk";
import { useTotalBalancesUsdForAnalytics } from "graphql/data/apollo/TokenBalancesProvider";
import { useAccount } from "hooks/useAccount";
import { useEthersWeb3Provider } from "hooks/useEthersProvider";
import { useGetTransactionDeadline } from "hooks/useTransactionDeadline";
import JSBI from "jsbi";
import useBlockNumber from "lib/hooks/useBlockNumber";
import { formatCommonPropertiesForTrade, formatSwapSignedAnalyticsEventProperties } from "lib/utils/analytics";
import { useCallback, useRef } from "react";
import { TradeFillType } from "state/routing/types";
import { useSwapAndLimitContext } from "state/swap/useSwapContext";
import { useUserSlippageTolerance } from "state/user/hooks";
import { trace } from "tracing/trace";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
import { t } from "uniswap/src/i18n";
import { logger } from "utilities/src/logger/logger";
import { useTrace } from "utilities/src/telemetry/trace/TraceContext";
import { calculateGasMargin } from "utils/calculateGasMargin";
import { UserRejectedRequestError, WrongChainError } from "utils/errors";
import { didUserReject, swapErrorToUserReadableMessage } from "utils/swapErrorToUserReadableMessage";
import { getWalletMeta } from "utils/walletMeta";
class GasEstimationError extends Error {
  constructor() {
    super(t("swap.error.expectedToFail"));
  }
}
class ModifiedSwapError extends Error {
  constructor() {
    super(t("swap.error.modifiedByWallet"));
  }
}
export function useUniversalRouterSwapCallback(trade, fiatValues, options) {
  const account = useAccount();
  const accountRef = useRef(account);
  accountRef.current = account;
  const { chainId } = useSwapAndLimitContext();
  const provider = useEthersWeb3Provider({ chainId });
  const providerRef = useRef(provider);
  providerRef.current = provider;
  const analyticsContext = useTrace();
  const blockNumber = useBlockNumber();
  const getDeadline = useGetTransactionDeadline();
  const isAutoSlippage = useUserSlippageTolerance()[0] === "auto";
  const portfolioBalanceUsd = useTotalBalancesUsdForAnalytics();
  return useCallback(
    () => trace({ name: "Swap (Classic)", op: "swap.classic" }, async (trace2) => {
      try {
        const account2 = accountRef.current;
        const provider2 = providerRef.current;
        if (account2.status !== "connected") {
          throw new Error("wallet not connected");
        }
        if (!provider2) {
          throw new Error("missing provider");
        }
        if (!trade) {
          throw new Error("missing trade");
        }
        const connectedChainId = await provider2.getSigner().getChainId();
        if (account2.chainId !== connectedChainId || account2.chainId !== chainId) {
          throw new WrongChainError();
        }
        const deadline = await getDeadline();
        trace2.setData("slippageTolerance", options.slippageTolerance.toFixed(2));
        const { calldata: data, value } = SwapRouter.swapCallParameters(trade, {
          slippageTolerance: options.slippageTolerance,
          deadlineOrPreviousBlockhash: deadline?.toString(),
          fee: options.feeOptions,
          recipient: account2.address
        });
        const tx = {
          from: account2.address,
          to: options.smartPoolAddress,
          data: MulticallExtended.encodeMulticall([PaymentsExtended.encodeWrapETH(JSBI.BigInt(value)), data]),
          value: "0x0"
        };
        let gasLimit;
        try {
          const gasEstimate = await provider2.estimateGas(tx);
          gasLimit = calculateGasMargin(gasEstimate);
          trace2.setData("gasLimit", gasLimit.toNumber());
        } catch (gasError) {
          sendAnalyticsEvent(SwapEventName.SWAP_ESTIMATE_GAS_CALL_FAILED, {
            ...formatCommonPropertiesForTrade(trade, options.slippageTolerance),
            ...analyticsContext,
            client_block_number: blockNumber,
            txRequest: tx,
            isAutoSlippage
          });
          const wrappedError = new Error("gas error", { cause: gasError });
          logger.warn("useUniversalRouter", "useUniversalRouterSwapCallback", "Failed to estimate gas", wrappedError);
          throw new GasEstimationError();
        }
        const response = await trace2.child(
          { name: "Send transaction", op: "wallet.send_transaction" },
          async (walletTrace) => {
            try {
              const provider3 = providerRef.current;
              if (!provider3) {
                throw new Error("missing provider");
              }
              return await provider3.getSigner().sendTransaction({ ...tx, gasLimit });
            } catch (error) {
              if (didUserReject(error)) {
                walletTrace.setStatus("cancelled");
                throw new UserRejectedRequestError(swapErrorToUserReadableMessage(error));
              } else {
                throw error;
              }
            }
          }
        );
        sendAnalyticsEvent(SwapEventName.SWAP_SIGNED, {
          ...formatSwapSignedAnalyticsEventProperties({
            trade,
            timeToSignSinceRequestMs: trace2.now(),
            allowedSlippage: options.slippageTolerance,
            fiatValues,
            txHash: response.hash,
            portfolioBalanceUsd
          }),
          ...analyticsContext,
          // TODO (WEB-2993): remove these after debugging missing user properties.
          [CustomUserProperties.WALLET_ADDRESS]: account2.address,
          [CustomUserProperties.WALLET_TYPE]: account2.connector.name,
          [CustomUserProperties.PEER_WALLET_AGENT]: provider2 ? getWalletMeta(provider2)?.agent : void 0
        });
        if (tx.data !== response.data) {
          sendAnalyticsEvent(SwapEventName.SWAP_MODIFIED_IN_WALLET, {
            txHash: response.hash,
            expected: tx.data,
            actual: response.data,
            ...analyticsContext
          });
          if (!response.data || response.data.length === 0 || response.data === "0x") {
            throw new ModifiedSwapError();
          }
        }
        return { type: TradeFillType.Classic, response, deadline };
      } catch (error) {
        if (error instanceof GasEstimationError) {
          throw error;
        } else if (error instanceof UserRejectedRequestError) {
          trace2.setStatus("cancelled");
          throw error;
        } else if (error instanceof ModifiedSwapError) {
          trace2.setError(error, "data_loss");
          throw error;
        } else {
          trace2.setError(error);
          throw Error(swapErrorToUserReadableMessage(error));
        }
      }
    }),
    [
      trade,
      chainId,
      getDeadline,
      options.slippageTolerance,
      options.feeOptions,
      options.smartPoolAddress,
      fiatValues,
      portfolioBalanceUsd,
      analyticsContext,
      blockNumber,
      isAutoSlippage
    ]
  );
}
