"use strict";
import { useAccount } from "hooks/useAccount";
import { useMemo } from "react";
import { useAppSelector } from "state/hooks";
import { deserializeToken } from "state/user/utils";
import { UserAddedToken } from "types/tokens";
function useUserAddedTokensOnChain(chainId) {
  const serializedTokensMap = useAppSelector(({ user: { tokens } }) => tokens);
  return useMemo(() => {
    if (!chainId) {
      return [];
    }
    const tokenMap = serializedTokensMap?.[chainId] ? Object.values(serializedTokensMap[chainId]).map((value) => deserializeToken(value, UserAddedToken)) : [];
    return tokenMap;
  }, [serializedTokensMap, chainId]);
}
export function useUserAddedTokens() {
  return useUserAddedTokensOnChain(useAccount().chainId);
}
export function useAllUserAddedTokens() {
  const serializedTokensMap = useAppSelector(({ user: { tokens } }) => tokens);
  return useMemo(() => {
    const tokenMap = Object.values(serializedTokensMap).flatMap(
      (chainTokens) => Object.values(chainTokens).map((value) => deserializeToken(value, UserAddedToken))
    );
    return tokenMap;
  }, [serializedTokensMap]);
}
