"use strict";
import { ChartType } from "components/Charts/utils";
import { checkDataQuality } from "components/Tokens/TokenDetails/ChartSection/util";
import useIsWindowVisible from "hooks/useIsWindowVisible";
import { useMemo } from "react";
import {
  HistoryDuration,
  PriceSource,
  ProtocolVersion,
  useDailyProtocolTvlQuery,
  useHistoricalProtocolVolumeQuery
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
function mapDataByTimestamp(v2Data, v3Data) {
  const dataByTime = {};
  v2Data?.forEach((v2Point) => {
    const timestamp = v2Point.timestamp;
    dataByTime[timestamp] = { [ProtocolVersion.V2]: v2Point.value, [ProtocolVersion.V3]: 0 };
  });
  v3Data?.forEach((v3Point) => {
    const timestamp = v3Point.timestamp;
    if (!dataByTime[timestamp]) {
      dataByTime[timestamp] = { [ProtocolVersion.V2]: 0, [ProtocolVersion.V3]: v3Point.value };
    } else {
      dataByTime[timestamp][ProtocolVersion.V3] = v3Point.value;
    }
  });
  return dataByTime;
}
export function useHistoricalProtocolVolume(chain, duration) {
  const isWindowVisible = useIsWindowVisible();
  const { data: queryData, loading } = useHistoricalProtocolVolumeQuery({
    variables: { chain, duration },
    skip: !isWindowVisible
  });
  return useMemo(() => {
    const dataByTime = mapDataByTimestamp(queryData?.v2HistoricalProtocolVolume, queryData?.v3HistoricalProtocolVolume);
    const entries = Object.entries(dataByTime).reduce((acc, [timestamp, values]) => {
      acc.push({
        time: Number(timestamp),
        values: {
          [PriceSource.SubgraphV2]: values[ProtocolVersion.V2],
          [PriceSource.SubgraphV3]: values[ProtocolVersion.V3]
        }
      });
      return acc;
    }, []);
    const dataQuality = checkDataQuality(entries, ChartType.VOLUME, duration);
    return { chartType: ChartType.VOLUME, entries, loading, dataQuality };
  }, [duration, loading, queryData?.v2HistoricalProtocolVolume, queryData?.v3HistoricalProtocolVolume]);
}
export function useDailyProtocolTVL(chain) {
  const isWindowVisible = useIsWindowVisible();
  const { data: queryData, loading } = useDailyProtocolTvlQuery({
    variables: { chain },
    skip: !isWindowVisible
  });
  return useMemo(() => {
    const dataByTime = mapDataByTimestamp(queryData?.v2DailyProtocolTvl, queryData?.v3DailyProtocolTvl);
    const entries = Object.entries(dataByTime).map(([timestamp, values]) => ({
      time: Number(timestamp),
      values: [values[ProtocolVersion.V2], values[ProtocolVersion.V3]]
    }));
    const dataQuality = checkDataQuality(entries, ChartType.TVL, HistoryDuration.Year);
    return { chartType: ChartType.TVL, entries, loading, dataQuality };
  }, [loading, queryData?.v2DailyProtocolTvl, queryData?.v3DailyProtocolTvl]);
}
