"use strict";
import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_DEADLINE_FROM_NOW } from "constants/misc";
import { RouterPreference } from "state/routing/types";
import { SlippageTolerance } from "state/user/types";
const currentTimestamp = () => (/* @__PURE__ */ new Date()).getTime();
function pairKey(token0Address, token1Address) {
  return `${token0Address};${token1Address}`;
}
export const initialState = {
  userLocale: null,
  userRouterPreference: RouterPreference.X,
  userHideClosedPositions: false,
  userSlippageTolerance: SlippageTolerance.Auto,
  userSlippageToleranceHasBeenMigratedToAuto: true,
  userDeadline: DEFAULT_DEADLINE_FROM_NOW,
  tokens: {},
  pairs: {},
  timestamp: currentTimestamp(),
  showSurveyPopup: void 0,
  originCountry: void 0
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserLocale(state, action) {
      if (action.payload.userLocale !== state.userLocale) {
        state.userLocale = action.payload.userLocale;
        state.timestamp = currentTimestamp();
      }
    },
    updateUserSlippageTolerance(state, action) {
      state.userSlippageTolerance = action.payload.userSlippageTolerance;
      state.timestamp = currentTimestamp();
    },
    updateUserDeadline(state, action) {
      state.userDeadline = action.payload.userDeadline;
      state.timestamp = currentTimestamp();
    },
    updateUserRouterPreference(state, action) {
      state.userRouterPreference = action.payload.userRouterPreference;
    },
    updateHideClosedPositions(state, action) {
      state.userHideClosedPositions = action.payload.userHideClosedPositions;
    },
    addSerializedToken(state, { payload: { serializedToken } }) {
      if (!state.tokens) {
        state.tokens = {};
      }
      state.tokens[serializedToken.chainId] = state.tokens[serializedToken.chainId] || {};
      state.tokens[serializedToken.chainId][serializedToken.address] = serializedToken;
      state.timestamp = currentTimestamp();
    },
    addSerializedPair(state, { payload: { serializedPair } }) {
      if (serializedPair.token0.chainId === serializedPair.token1.chainId && serializedPair.token0.address !== serializedPair.token1.address) {
        const chainId = serializedPair.token0.chainId;
        state.pairs[chainId] = state.pairs[chainId] || {};
        state.pairs[chainId][pairKey(serializedPair.token0.address, serializedPair.token1.address)] = serializedPair;
      }
      state.timestamp = currentTimestamp();
    },
    setOriginCountry(state, { payload: country }) {
      state.originCountry = country;
    }
  }
});
export const {
  addSerializedPair,
  addSerializedToken,
  setOriginCountry,
  updateHideClosedPositions,
  updateUserRouterPreference,
  updateUserDeadline,
  updateUserLocale,
  updateUserSlippageTolerance
} = userSlice.actions;
export default userSlice.reducer;
