"use strict";
import { getTokensAsync } from "components/AccountDrawer/MiniPortfolio/Pools/getTokensAsync";
import { useInterfaceMulticallContracts } from "components/AccountDrawer/MiniPortfolio/Pools/hooks";
import { atom, useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import ms from "ms";
import { useCallback } from "react";
import { deserializeToken, serializeToken } from "state/user/utils";
import { buildCurrencyKey, currencyKey } from "utils/currencyKey";
const POSITION_CACHE_EXPIRY = ms(`1m`);
const cachedPositionsAtom = atom({});
export function useCachedPositions(account) {
  const [cachedPositions, setCachedPositions] = useAtom(cachedPositionsAtom);
  const setPositionsAndStaleTimeout = useCallback(
    (positions) => {
      setCachedPositions((cache) => ({ ...cache, [account]: { result: positions, stale: false } }));
      setTimeout(
        () => setCachedPositions((cache) => {
          if (positions === cache[account]?.result) {
            return { ...cache, [account]: { result: positions, stale: true } };
          } else {
            return cache;
          }
        }),
        POSITION_CACHE_EXPIRY
      );
    },
    [account, setCachedPositions]
  );
  return [cachedPositions[account], setPositionsAndStaleTimeout];
}
const poolAddressKey = (details, chainId) => `${chainId}-${details.token0}-${details.token1}-${details.fee}`;
const poolAddressCacheAtom = atomWithStorage("poolCache", {});
export function usePoolAddressCache() {
  const [cache, updateCache] = useAtom(poolAddressCacheAtom);
  const get = useCallback(
    (details, chainId) => cache[poolAddressKey(details, chainId)],
    [cache]
  );
  const set = useCallback(
    (details, chainId, address) => updateCache((c) => ({ ...c, [poolAddressKey(details, chainId)]: address })),
    [updateCache]
  );
  return { get, set };
}
const tokenCacheAtom = atomWithStorage("cachedAsyncTokens", {});
function useTokenCache() {
  const [cache, setCache] = useAtom(tokenCacheAtom);
  const get = useCallback(
    (chainId, address) => {
      const entry = cache[buildCurrencyKey(chainId, address)];
      return entry ? deserializeToken(entry) : void 0;
    },
    [cache]
  );
  const set = useCallback(
    (token) => {
      if (token) {
        setCache((cache2) => ({ ...cache2, [currencyKey(token)]: serializeToken(token) }));
      }
    },
    [setCache]
  );
  return { get, set };
}
export function useGetCachedTokens(chains) {
  const multicallContracts = useInterfaceMulticallContracts(chains);
  const tokenCache = useTokenCache();
  const fetchRemoteTokens = useCallback(
    async (addresses, chainId) => {
      const fetched = await getTokensAsync(addresses, chainId, multicallContracts[chainId]);
      Object.values(fetched).forEach(tokenCache.set);
      return fetched;
    },
    [multicallContracts, tokenCache]
  );
  const getTokens = useCallback(
    async (addresses, chainId) => {
      const local = {};
      const missing = /* @__PURE__ */ new Set();
      addresses.forEach((address) => {
        const cached = tokenCache.get(chainId, address);
        cached ? local[address] = cached : missing.add(address);
      });
      const fetched = await fetchRemoteTokens([...missing], chainId);
      return { ...local, ...fetched };
    },
    [fetchRemoteTokens, tokenCache]
  );
  return getTokens;
}
