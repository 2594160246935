"use strict";
import { useConnectorWithId } from "components/WalletModal/useOrderedConnections";
import { CONNECTION } from "components/Web3Provider/constants";
import { useAccount } from "hooks/useAccount";
import { FeatureFlags } from "uniswap/src/features/gating/flags";
import { useFeatureFlag } from "uniswap/src/features/gating/hooks";
export function useUniswapWalletOptions() {
  const shouldDisplayUniswapWalletOptions = false;
  const isGALive = useFeatureFlag(FeatureFlags.ExtensionLaunch);
  const isExtensionInstalled = Boolean(useConnectorWithId(CONNECTION.UNISWAP_EXTENSION_RDNS));
  return (isExtensionInstalled || isGALive) && shouldDisplayUniswapWalletOptions;
}
export function useIsUniExtensionAvailable() {
  const isGALive = useFeatureFlag(FeatureFlags.ExtensionLaunch);
  const currentConnector = useAccount().connector;
  const currentConnectIsNotUniExtension = currentConnector && currentConnector.id !== CONNECTION.UNISWAP_EXTENSION_RDNS;
  return isGALive && !currentConnectIsNotUniExtension;
}
