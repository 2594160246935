"use strict";
import { nativeOnChain, WRAPPED_NATIVE_CURRENCY } from "constants/tokens";
export function unwrappedToken(currency) {
  if (currency.isNative) {
    return currency;
  }
  if (WRAPPED_NATIVE_CURRENCY[currency.chainId]?.equals(currency)) {
    return nativeOnChain(currency.chainId);
  }
  return currency;
}
