"use strict";
import { createAction } from "@reduxjs/toolkit";
export const fetchTokenList = {
  pending: createAction("poolsList/fetchTokenList/pending"),
  fulfilled: createAction("poolsList/fetchTokenList/fulfilled"),
  rejected: createAction("poolsList/fetchTokenList/rejected")
};
export const addList = createAction("poolsList/addList");
export const removeList = createAction("poolsList/removeList");
export const acceptListUpdate = createAction("poolsList/acceptListUpdate");
