"use strict";
import { tokensToChainTokenMap } from "lib/hooks/useTokenList/utils";
import { useMemo } from "react";
import { useAppSelector } from "state/hooks";
import { logger } from "utilities/src/logger/logger";
export function usePoolsList() {
  return useAppSelector((state) => state.poolsList.byUrl);
}
function combineMaps(map1, map2) {
  const chainIds = Object.keys(
    Object.keys(map1).concat(Object.keys(map2)).reduce((memo, value) => {
      memo[value] = true;
      return memo;
    }, {})
  ).map((id) => parseInt(id));
  return chainIds.reduce((memo, chainId) => {
    memo[chainId] = {
      ...map2[chainId],
      // map1 takes precedence
      ...map1[chainId]
    };
    return memo;
  }, {});
}
export function usePoolMapFromUrl(urls) {
  const lists = usePoolsList();
  return useMemo(() => {
    if (!urls) {
      return {};
    }
    return urls.slice().reduce((allTokens, currentUrl) => {
      const current = lists?.[currentUrl]?.current;
      if (!current) {
        return allTokens;
      }
      try {
        return combineMaps(allTokens, tokensToChainTokenMap(current));
      } catch (error) {
        logger.warn("lists/poolsList/hooks", "usePoolMapFromUrl", "Failed to combine pools", error);
        return allTokens;
      }
    }, {});
  }, [lists, urls]);
}
export function usePoolsFromUrl(urls) {
  const lists = usePoolsList();
  return useMemo(() => {
    if (!urls) {
      return [];
    }
    return lists[urls[0]]?.current?.tokens;
  }, [lists, urls]);
}
