"use strict";
import { ApolloError } from "@apollo/client";
import { createColumnHelper } from "@tanstack/react-table";
import { InterfaceElementName } from "@uniswap/analytics-events";
import { DoubleCurrencyAndChainLogo } from "components/DoubleLogo";
import { Table } from "components/Table";
import { Cell } from "components/Table/Cell";
import { ClickableHeaderRow, HeaderArrow, HeaderSortText } from "components/Table/styled";
import { EllipsisText } from "components/Tokens/TokenTable";
import { MAX_WIDTH_MEDIA_BREAKPOINT } from "components/Tokens/constants";
import { exploreSearchStringAtom } from "components/Tokens/state";
import { MouseoverTooltip } from "components/Tooltip";
import { chainIdToBackendChain, useChainFromUrlParam } from "constants/chains";
import { BIPS_BASE } from "constants/misc";
import { useUpdateManualOutage } from "featureFlags/flags/outageBanner";
import { PoolSortFields, useTopPools } from "graphql/data/pools/useTopPools";
import { OrderDirection, getSupportedGraphQlChain, gqlToCurrency, unwrapToken } from "graphql/data/util";
import { useAtom } from "jotai";
import { atomWithReset, useAtomValue, useResetAtom, useUpdateAtom } from "jotai/utils";
import { useCallback, useEffect, useMemo } from "react";
import { Flex, Text, styled } from "ui/src";
import { ProtocolVersion } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { Trans } from "uniswap/src/i18n";
import { NumberType, useFormatter } from "utils/formatNumbers";
const HEADER_DESCRIPTIONS = {
  [PoolSortFields.TVL]: void 0,
  [PoolSortFields.Volume24h]: void 0,
  [PoolSortFields.VolumeWeek]: void 0,
  [PoolSortFields.TxCount]: void 0,
  [PoolSortFields.OneDayApr]: <Trans i18nKey="pool.apr.feesNotice" />
};
const TableWrapper = styled(Flex, {
  m: "0 auto",
  maxWidth: MAX_WIDTH_MEDIA_BREAKPOINT
});
const Badge = styled(Text, {
  py: 2,
  px: 6,
  backgroundColor: "$surface2",
  borderRadius: "$rounded6",
  variant: "body4",
  color: "$neutral2"
});
export var PoolTableColumns = /* @__PURE__ */ ((PoolTableColumns2) => {
  PoolTableColumns2[PoolTableColumns2["Index"] = 0] = "Index";
  PoolTableColumns2[PoolTableColumns2["PoolDescription"] = 1] = "PoolDescription";
  PoolTableColumns2[PoolTableColumns2["Transactions"] = 2] = "Transactions";
  PoolTableColumns2[PoolTableColumns2["TVL"] = 3] = "TVL";
  PoolTableColumns2[PoolTableColumns2["Volume24h"] = 4] = "Volume24h";
  PoolTableColumns2[PoolTableColumns2["VolumeWeek"] = 5] = "VolumeWeek";
  PoolTableColumns2[PoolTableColumns2["OneDayApr"] = 6] = "OneDayApr";
  return PoolTableColumns2;
})(PoolTableColumns || {});
function PoolDescription({
  token0,
  token1,
  feeTier,
  chainId,
  protocolVersion = ProtocolVersion.V3
}) {
  const currencies = [gqlToCurrency(token0), gqlToCurrency(token1)];
  return <Flex row gap="$gap8" alignItems="center">
    <DoubleCurrencyAndChainLogo chainId={chainId} currencies={currencies} size={28} />
    <EllipsisText>
      {token0.symbol}
      {"/"}
      {token1.symbol}
    </EllipsisText>
    {protocolVersion === ProtocolVersion.V2 && <Badge>{protocolVersion.toLowerCase()}</Badge>}
    <Badge>
      {feeTier / BIPS_BASE}
      {"%"}
    </Badge>
  </Flex>;
}
export const sortMethodAtom = atomWithReset(PoolSortFields.TVL);
export const sortAscendingAtom = atomWithReset(false);
function useSetSortMethod(newSortMethod) {
  const [sortMethod, setSortMethod] = useAtom(sortMethodAtom);
  const setSortAscending = useUpdateAtom(sortAscendingAtom);
  return useCallback(() => {
    if (sortMethod === newSortMethod) {
      setSortAscending((sortAscending) => !sortAscending);
    } else {
      setSortMethod(newSortMethod);
      setSortAscending(false);
    }
  }, [sortMethod, setSortMethod, setSortAscending, newSortMethod]);
}
const HEADER_TEXT = {
  [PoolSortFields.TVL]: <Trans i18nKey="common.totalValueLocked" />,
  [PoolSortFields.Volume24h]: <Trans i18nKey="stats.volume.1d" />,
  [PoolSortFields.VolumeWeek]: <Trans i18nKey="pool.volume.sevenDay" />,
  [PoolSortFields.OneDayApr]: <Trans i18nKey="pool.apr.oneDay" />,
  [PoolSortFields.TxCount]: <Trans i18nKey="common.transactions" />
};
function PoolTableHeader({
  category,
  isCurrentSortMethod,
  direction
}) {
  const handleSortCategory = useSetSortMethod(category);
  return <MouseoverTooltip disabled={!HEADER_DESCRIPTIONS[category]} text={HEADER_DESCRIPTIONS[category]} placement="top"><ClickableHeaderRow justifyContent="flex-end" onPress={handleSortCategory}>
    {isCurrentSortMethod && <HeaderArrow direction={direction} />}
    <HeaderSortText active={isCurrentSortMethod}>{HEADER_TEXT[category]}</HeaderSortText>
  </ClickableHeaderRow></MouseoverTooltip>;
}
export function TopPoolTable() {
  const chain = getSupportedGraphQlChain(useChainFromUrlParam(), { fallbackToEthereum: true });
  const sortMethod = useAtomValue(sortMethodAtom);
  const sortAscending = useAtomValue(sortAscendingAtom);
  const resetSortMethod = useResetAtom(sortMethodAtom);
  const resetSortAscending = useResetAtom(sortAscendingAtom);
  useEffect(() => {
    resetSortMethod();
    resetSortAscending();
  }, [resetSortAscending, resetSortMethod]);
  const { topPools, loading, errorV3, errorV2 } = useTopPools(
    { sortBy: sortMethod, sortDirection: sortAscending ? OrderDirection.Asc : OrderDirection.Desc },
    chain.id
  );
  const combinedError = errorV2 && errorV3 ? new ApolloError({ errorMessage: `Could not retrieve V2 and V3 Top Pools on chain: ${chain.id}` }) : void 0;
  const allDataStillLoading = loading && !topPools.length;
  useUpdateManualOutage({ chainId: chain.id, errorV3, errorV2 });
  return <TableWrapper data-testid="top-pools-explore-table"><PoolsTable
    pools={topPools}
    loading={allDataStillLoading}
    error={combinedError}
    chainId={chain.id}
    maxWidth={1200}
  /></TableWrapper>;
}
export function PoolsTable({
  pools,
  loading,
  error,
  loadMore,
  chainId,
  maxWidth,
  maxHeight,
  hiddenColumns
}) {
  const { formatNumber, formatPercent } = useFormatter();
  const sortAscending = useAtomValue(sortAscendingAtom);
  const orderDirection = sortAscending ? OrderDirection.Asc : OrderDirection.Desc;
  const sortMethod = useAtomValue(sortMethodAtom);
  const filterString = useAtomValue(exploreSearchStringAtom);
  const poolTableValues = useMemo(
    () => pools?.map((pool, index) => {
      const poolSortRank = index + 1;
      return {
        index: poolSortRank,
        poolDescription: <PoolDescription
          token0={unwrapToken(chainId, pool.token0)}
          token1={unwrapToken(chainId, pool.token1)}
          feeTier={pool.feeTier}
          chainId={chainId}
          protocolVersion={pool.protocolVersion}
        />,
        txCount: pool.txCount,
        tvl: pool.tvl,
        volume24h: pool.volume24h,
        volumeWeek: pool.volumeWeek,
        oneDayApr: pool.oneDayApr,
        link: `/explore/pools/${chainIdToBackendChain({ chainId, withFallback: true }).toLowerCase()}/${pool.hash}`,
        analytics: {
          elementName: InterfaceElementName.POOLS_TABLE_ROW,
          properties: {
            chain_id: chainId,
            pool_address: pool.hash,
            token0_address: pool.token0.address,
            token0_symbol: pool.token0.symbol,
            token1_address: pool.token1.address,
            token1_symbol: pool.token1.symbol,
            pool_list_index: index,
            pool_list_rank: poolSortRank,
            pool_list_length: pools.length,
            search_pool_input: filterString
          }
        }
      };
    }) ?? [],
    [chainId, filterString, pools]
  );
  const showLoadingSkeleton = loading || !!error;
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();
    return [
      !hiddenColumns?.includes(0 /* Index */) ? columnHelper.accessor((row) => row.index, {
        id: "index",
        header: () => <Cell justifyContent="center" minWidth={44}><Text variant="body2" color="$neutral2">#</Text></Cell>,
        cell: (index) => <Cell justifyContent="center" loading={showLoadingSkeleton} minWidth={44}><Text variant="body2" color="$neutral2">{index.getValue?.()}</Text></Cell>
      }) : null,
      !hiddenColumns?.includes(1 /* PoolDescription */) ? columnHelper.accessor((row) => row.poolDescription, {
        id: "poolDescription",
        header: () => <Cell justifyContent="flex-start" width={240} grow><Text variant="body2" color="$neutral2"><Trans i18nKey="common.pool" /></Text></Cell>,
        cell: (poolDescription) => <Cell justifyContent="flex-start" alignItems="center" loading={showLoadingSkeleton} width={240} grow>{poolDescription.getValue?.()}</Cell>
      }) : null,
      !hiddenColumns?.includes(2 /* Transactions */) ? columnHelper.accessor((row) => row.txCount, {
        id: "transactions",
        header: () => <Cell minWidth={120} grow><PoolTableHeader
          category={PoolSortFields.TxCount}
          isCurrentSortMethod={sortMethod === PoolSortFields.TxCount}
          direction={orderDirection}
        /></Cell>,
        cell: (txCount) => <Cell loading={showLoadingSkeleton} minWidth={120} grow><Text variant="body2" color="$neutral1">{formatNumber({ input: txCount.getValue?.(), type: NumberType.NFTCollectionStats })}</Text></Cell>
      }) : null,
      !hiddenColumns?.includes(3 /* TVL */) ? columnHelper.accessor((row) => row.tvl, {
        id: "tvl",
        header: () => <Cell minWidth={120} grow><PoolTableHeader
          category={PoolSortFields.TVL}
          isCurrentSortMethod={sortMethod === PoolSortFields.TVL}
          direction={orderDirection}
        /></Cell>,
        cell: (tvl) => <Cell loading={showLoadingSkeleton} minWidth={120} grow><Text variant="body2" color="$neutral1">{formatNumber({ input: tvl.getValue?.(), type: NumberType.FiatTokenStats })}</Text></Cell>
      }) : null,
      !hiddenColumns?.includes(4 /* Volume24h */) ? columnHelper.accessor((row) => row.volume24h, {
        id: "volume24h",
        header: () => <Cell minWidth={120} grow><PoolTableHeader
          category={PoolSortFields.Volume24h}
          isCurrentSortMethod={sortMethod === PoolSortFields.Volume24h}
          direction={orderDirection}
        /></Cell>,
        cell: (volume24h) => <Cell minWidth={120} loading={showLoadingSkeleton} grow><Text variant="body2" color="$neutral1">{formatNumber({ input: volume24h.getValue?.(), type: NumberType.FiatTokenStats })}</Text></Cell>
      }) : null,
      !hiddenColumns?.includes(5 /* VolumeWeek */) ? columnHelper.accessor((row) => row.volumeWeek, {
        id: "volumeWeek",
        header: () => <Cell minWidth={120} grow><PoolTableHeader
          category={PoolSortFields.VolumeWeek}
          isCurrentSortMethod={sortMethod === PoolSortFields.VolumeWeek}
          direction={orderDirection}
        /></Cell>,
        cell: (volumeWeek) => <Cell minWidth={120} loading={showLoadingSkeleton} grow><Text variant="body2" color="$neutral1">{formatNumber({ input: volumeWeek.getValue?.(), type: NumberType.FiatTokenStats })}</Text></Cell>
      }) : null,
      !hiddenColumns?.includes(6 /* OneDayApr */) ? columnHelper.accessor((row) => row.oneDayApr, {
        id: "oneDayApr",
        header: () => <Cell minWidth={100} grow><PoolTableHeader
          category={PoolSortFields.OneDayApr}
          isCurrentSortMethod={sortMethod === PoolSortFields.OneDayApr}
          direction={orderDirection}
        /></Cell>,
        cell: (oneDayApr) => <Cell minWidth={100} loading={showLoadingSkeleton} grow><Text variant="body2" color="$neutral1">{formatPercent(oneDayApr.getValue?.())}</Text></Cell>
      }) : null
      // Filter out null values
    ].filter(Boolean);
  }, [formatNumber, formatPercent, hiddenColumns, orderDirection, showLoadingSkeleton, sortMethod]);
  return <Table
    columns={columns}
    data={poolTableValues}
    loading={loading}
    error={error}
    loadMore={loadMore}
    maxWidth={maxWidth}
    maxHeight={maxHeight}
  />;
}
