"use strict";
import { Interface } from "@ethersproject/abi";
import StakingRewardsJSON from "@uniswap/liquidity-staker/build/StakingRewards.json";
import { CurrencyAmount } from "@uniswap/sdk-core";
import { Pair } from "@uniswap/v2-sdk";
import { POP_ADDRESSES } from "constants/addresses";
import { DAI, GRG, UNI, USDC_MAINNET, USDT, WBTC, WRAPPED_NATIVE_CURRENCY } from "constants/tokens";
import { useAccount } from "hooks/useAccount";
import { useEthersWeb3Provider } from "hooks/useEthersProvider";
import { useContract } from "hooks/useContract";
import useCurrentBlockTimestamp from "hooks/useCurrentBlockTimestamp";
import JSBI from "jsbi";
import {
  NEVER_RELOAD,
  useMultipleContractSingleData,
  useSingleCallResult,
  useSingleContractMultipleData
} from "lib/hooks/multicall";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { StakeStatus, useStakingContract, useStakingProxyContract } from "state/governance/hooks";
import { usePoolExtendedContract } from "state/pool/hooks";
import { useTransactionAdder } from "state/transactions/hooks";
import { TransactionType } from "state/transactions/types";
import POP_ABI from "uniswap/src/abis/pop.json";
import { UniverseChainId } from "uniswap/src/types/chains";
import { calculateGasMargin } from "utils/calculateGasMargin";
import { logger } from "utilities/src/logger/logger";
const STAKING_REWARDS_INTERFACE = new Interface(StakingRewardsJSON.abi);
export const STAKING_GENESIS = 1600387200;
const STAKING_REWARDS_INFO = {
  1: [
    {
      tokens: [WRAPPED_NATIVE_CURRENCY[UniverseChainId.Mainnet], DAI],
      stakingRewardAddress: "0xa1484C3aa22a66C62b77E0AE78E15258bd0cB711"
    },
    {
      tokens: [WRAPPED_NATIVE_CURRENCY[UniverseChainId.Mainnet], USDC_MAINNET],
      stakingRewardAddress: "0x7FBa4B8Dc5E7616e59622806932DBea72537A56b"
    },
    {
      tokens: [WRAPPED_NATIVE_CURRENCY[UniverseChainId.Mainnet], USDT],
      stakingRewardAddress: "0x6C3e4cb2E96B01F4b866965A91ed4437839A121a"
    },
    {
      tokens: [WRAPPED_NATIVE_CURRENCY[UniverseChainId.Mainnet], WBTC],
      stakingRewardAddress: "0xCA35e32e7926b96A9988f61d510E038108d8068e"
    }
  ]
};
export function useStakingInfo(pairToFilterBy) {
  const account = useAccount();
  const currentBlockTimestamp = useCurrentBlockTimestamp(NEVER_RELOAD);
  const info = useMemo(
    () => account.chainId ? STAKING_REWARDS_INFO[account.chainId]?.filter(
      (stakingRewardInfo) => pairToFilterBy === void 0 ? true : pairToFilterBy === null ? false : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) && pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
    ) ?? [] : [],
    [account.chainId, pairToFilterBy]
  );
  const uni = account.chainId ? UNI[account.chainId] : void 0;
  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info]);
  const accountArg = useMemo(() => [account.address], [account.address]);
  const balances = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, "balanceOf", accountArg);
  const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, "earned", accountArg);
  const totalSupplies = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, "totalSupply");
  const rewardRates = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    "rewardRate",
    void 0,
    NEVER_RELOAD
  );
  const periodFinishes = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    "periodFinish",
    void 0,
    NEVER_RELOAD
  );
  return useMemo(() => {
    if (!account.chainId || !uni) {
      return [];
    }
    return rewardsAddresses.reduce((memo, rewardsAddress, index) => {
      const balanceState = balances[index];
      const earnedAmountState = earnedAmounts[index];
      const totalSupplyState = totalSupplies[index];
      const rewardRateState = rewardRates[index];
      const periodFinishState = periodFinishes[index];
      if (
        // these may be undefined if not logged in
        !balanceState?.loading && !earnedAmountState?.loading && // always need these
        totalSupplyState && !totalSupplyState.loading && rewardRateState && !rewardRateState.loading && periodFinishState && !periodFinishState.loading
      ) {
        if (balanceState?.error || earnedAmountState?.error || totalSupplyState.error || rewardRateState.error || periodFinishState.error) {
          logger.warn("stake/hooks", "useStakingInfo", "Failed to load staking rewards info");
          return memo;
        }
        const tokens = info[index].tokens;
        const dummyPair = new Pair(
          CurrencyAmount.fromRawAmount(tokens[0], "0"),
          CurrencyAmount.fromRawAmount(tokens[1], "0")
        );
        const stakedAmount = CurrencyAmount.fromRawAmount(
          dummyPair.liquidityToken,
          JSBI.BigInt(balanceState?.result?.[0] ?? 0)
        );
        const totalStakedAmount = CurrencyAmount.fromRawAmount(
          dummyPair.liquidityToken,
          JSBI.BigInt(totalSupplyState.result?.[0])
        );
        const totalRewardRate = CurrencyAmount.fromRawAmount(uni, JSBI.BigInt(rewardRateState.result?.[0]));
        const getHypotheticalRewardRate = (stakedAmount2, totalStakedAmount2, totalRewardRate2) => {
          return CurrencyAmount.fromRawAmount(
            uni,
            JSBI.greaterThan(totalStakedAmount2.quotient, JSBI.BigInt(0)) ? JSBI.divide(JSBI.multiply(totalRewardRate2.quotient, stakedAmount2.quotient), totalStakedAmount2.quotient) : JSBI.BigInt(0)
          );
        };
        const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate);
        const periodFinishSeconds = periodFinishState.result?.[0]?.toNumber();
        const periodFinishMs = periodFinishSeconds * 1e3;
        const active = periodFinishSeconds && currentBlockTimestamp ? periodFinishSeconds > currentBlockTimestamp.toNumber() : true;
        memo.push({
          stakingRewardAddress: rewardsAddress,
          tokens: info[index].tokens,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : void 0,
          earnedAmount: CurrencyAmount.fromRawAmount(uni, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate,
          stakedAmount,
          totalStakedAmount,
          getHypotheticalRewardRate,
          active
        });
      }
      return memo;
    }, []);
  }, [
    balances,
    account.chainId,
    currentBlockTimestamp,
    earnedAmounts,
    info,
    periodFinishes,
    rewardRates,
    rewardsAddresses,
    totalSupplies,
    uni
  ]);
}
export function useFreeStakeBalance(isDelegateFreeStake) {
  const account = useAccount();
  const grg = useMemo(() => account.chainId ? GRG[account.chainId] : void 0, [account.chainId]);
  const stakingContract = useStakingContract();
  const { poolAddress: poolAddressFromUrl } = useParams();
  const freeStake = useSingleCallResult(stakingContract ?? void 0, "getOwnerStakeByStatus", [
    isDelegateFreeStake ? account.address : poolAddressFromUrl ?? account.address,
    StakeStatus.UNDELEGATED
  ])?.result?.[0];
  return freeStake && grg ? CurrencyAmount.fromRawAmount(
    grg,
    Number(freeStake.currentEpochBalance) > Number(freeStake.nextEpochBalance) ? freeStake.nextEpochBalance : freeStake.currentEpochBalance
  ) : void 0;
}
export function useUnclaimedRewards(poolIds) {
  const account = useAccount();
  const grg = useMemo(() => account.chainId ? GRG[account.chainId] : void 0, [account.chainId]);
  const stakingContract = useStakingContract();
  const { poolAddress: poolAddressFromUrl } = useParams();
  const farmer = poolAddressFromUrl ?? account.address;
  const inputs = useMemo(() => {
    return poolIds.map((poolId) => {
      return [poolId, farmer];
    });
  }, [farmer, poolIds]);
  const unclaimedRewards = useSingleContractMultipleData(
    stakingContract ?? void 0,
    "computeRewardBalanceOfDelegator",
    inputs
  );
  return useMemo(() => {
    if (!unclaimedRewards || !grg) {
      return void 0;
    }
    return unclaimedRewards.map((reward, i) => {
      const value = reward?.result?.[0];
      return {
        yieldAmount: CurrencyAmount.fromRawAmount(grg, value ?? JSBI.BigInt(0)),
        yieldPoolId: poolIds[i]
      };
    }).filter((p) => JSBI.greaterThan(p.yieldAmount.quotient, JSBI.BigInt(0)));
  }, [grg, unclaimedRewards, poolIds]);
}
export function useUserStakeBalances(poolIds) {
  const account = useAccount();
  const grg = useMemo(() => account.chainId ? GRG[account.chainId] : void 0, [account.chainId]);
  const stakingContract = useStakingContract();
  const inputs = useMemo(() => {
    return poolIds.map((poolId) => {
      return [account.address, poolId];
    });
  }, [account, poolIds]);
  const userStakeBalances = useSingleContractMultipleData(
    stakingContract ?? void 0,
    "getStakeDelegatedToPoolByOwner",
    inputs
  );
  return useMemo(() => {
    if (!userStakeBalances || !grg) {
      return void 0;
    }
    return userStakeBalances.map((balance) => {
      const stake = balance?.result?.[0].nextEpochBalance;
      const stakeAmount = CurrencyAmount.fromRawAmount(grg, stake ?? JSBI.BigInt(0));
      return {
        stake: stakeAmount,
        hasStake: JSBI.greaterThan(stakeAmount.quotient, JSBI.BigInt(0))
      };
    });
  }, [grg, userStakeBalances]);
}
export function useUnstakeCallback() {
  const account = useAccount();
  const provider = useEthersWeb3Provider();
  const stakingContract = useStakingContract();
  const { poolAddress: poolAddressFromUrl } = useParams();
  const poolContract = usePoolExtendedContract(poolAddressFromUrl ?? void 0);
  const addTransaction = useTransactionAdder();
  return useCallback(
    (amount, isPool) => {
      if (!provider || !account.chainId || !account.address) {
        return void 0;
      }
      if (!stakingContract) {
        throw new Error("No Staking Proxy Contract!");
      }
      if (isPool && !poolContract) {
        throw new Error("No Pool Contract!");
      }
      if (!isPool) {
        return stakingContract.estimateGas.unstake(amount.quotient.toString(), {}).then((estimatedGasLimit) => {
          return stakingContract.unstake(amount.quotient.toString(), {
            value: null,
            gasLimit: calculateGasMargin(estimatedGasLimit)
          }).then((response) => {
            addTransaction(response, {
              type: TransactionType.CLAIM,
              recipient: account.address ?? ""
            });
            return response.hash;
          });
        });
      } else {
        return poolContract?.estimateGas.unstake(amount.quotient.toString(), {}).then((estimatedGasLimit) => {
          return poolContract?.unstake(amount.quotient.toString(), {
            value: null,
            gasLimit: calculateGasMargin(estimatedGasLimit)
          }).then((response) => {
            addTransaction(response, {
              type: TransactionType.CLAIM,
              recipient: poolContract.address
            });
            return response.hash;
          });
        });
      }
    },
    [account.address, account.chainId, provider, poolContract, stakingContract, addTransaction]
  );
}
export function useHarvestCallback() {
  const account = useAccount();
  const provider = useEthersWeb3Provider();
  const stakingContract = useStakingContract();
  const stakingProxy = useStakingProxyContract();
  const { poolAddress: poolAddressFromUrl } = useParams();
  const poolContract = usePoolExtendedContract(poolAddressFromUrl ?? void 0);
  const addTransaction = useTransactionAdder();
  return useCallback(
    (poolIds, isPool) => {
      if (!provider || !account.chainId || !account.address) {
        return void 0;
      }
      if (!stakingContract || !stakingProxy) {
        throw new Error("No Staking Proxy Contract!");
      }
      if (isPool && !poolContract) {
        throw new Error("No Pool Contract!");
      }
      const harvestCalls = [];
      for (const poolId of poolIds) {
        const harvestCall = !isPool ? stakingContract.interface.encodeFunctionData("withdrawDelegatorRewards", [poolId]) : poolContract?.interface.encodeFunctionData("withdrawDelegatorRewards");
        if (harvestCall) {
          harvestCalls.push(harvestCall);
        }
      }
      if (!isPool) {
        return stakingProxy.estimateGas.batchExecute(harvestCalls, {}).then((estimatedGasLimit) => {
          return stakingProxy.batchExecute(harvestCalls, {
            value: null,
            gasLimit: calculateGasMargin(estimatedGasLimit)
          }).then((response) => {
            addTransaction(response, {
              type: TransactionType.CLAIM,
              recipient: account.address ?? ""
            });
            return response.hash;
          });
        });
      } else {
        return poolContract?.estimateGas.withdrawDelegatorRewards({}).then((estimatedGasLimit) => {
          return poolContract?.withdrawDelegatorRewards({
            value: null,
            gasLimit: calculateGasMargin(estimatedGasLimit)
          }).then((response) => {
            addTransaction(response, {
              type: TransactionType.CLAIM,
              recipient: poolContract.address
            });
            return response.hash;
          });
        });
      }
    },
    [account.address, account.chainId, provider, poolContract, stakingContract, stakingProxy, addTransaction]
  );
}
export function usePopContract() {
  const account = useAccount();
  return useContract(
    account.chainId ? POP_ADDRESSES[account.chainId] : void 0,
    POP_ABI,
    true
  );
}
export function useRaceCallback() {
  const account = useAccount();
  const provider = useEthersWeb3Provider();
  const popContract = usePopContract();
  const addTransaction = useTransactionAdder();
  return useCallback(
    (poolAddress) => {
      if (!provider || !account.chainId || !account.address) {
        return void 0;
      }
      if (!popContract) {
        throw new Error("No PoP Contract!");
      }
      return popContract.estimateGas.creditPopRewardToStakingProxy(poolAddress, {}).then((estimatedGasLimit) => {
        return popContract.creditPopRewardToStakingProxy(poolAddress, {
          value: null,
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then((response) => {
          addTransaction(response, {
            type: TransactionType.CLAIM,
            recipient: account.address ?? ""
          });
          return response.hash;
        });
      });
    },
    [account.address, account.chainId, provider, popContract, addTransaction]
  );
}
