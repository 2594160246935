"use strict";
import { getVersionUpgrade, VersionUpgrade } from "@uniswap/token-lists";
import { useWeb3React } from "@web3-react/core";
import { POOLS_LIST } from "constants/lists";
import { useFetchPoolListCallback } from "hooks/useFetchPoolListCallback";
import useIsWindowVisible from "hooks/useIsWindowVisible";
import { useStateRehydrated } from "hooks/useStateRehydrated";
import useInterval from "lib/hooks/useInterval";
import ms from "ms";
import { useCallback, useEffect } from "react";
import { useAppDispatch } from "state/hooks";
import { acceptListUpdate } from "state/lists/poolsList/actions";
import { usePoolsList } from "state/lists/poolsList/hooks";
import { logger } from "utilities/src/logger/logger";
export default function Updater() {
  const { provider } = useWeb3React();
  const dispatch = useAppDispatch();
  const isWindowVisible = useIsWindowVisible();
  const lists = usePoolsList();
  const rehydrated = useStateRehydrated();
  const fetchList = useFetchPoolListCallback();
  const fetchAllListsCallback = useCallback(() => {
    if (!isWindowVisible) {
      return;
    }
    POOLS_LIST.forEach((url) => {
      const isUnsupportedList = false;
      fetchList(url, isUnsupportedList).catch(
        (error) => logger.debug("lists/poolsList/updater", "Updater", "interval list fetching error", error)
      );
    });
  }, [fetchList, isWindowVisible]);
  useInterval(fetchAllListsCallback, provider ? ms(`10m`) : null);
  useEffect(() => {
    if (!rehydrated || !lists) {
      return;
    }
    Object.keys(lists).forEach((listUrl) => {
      const list = lists[listUrl];
      if (!list.current && !list.loadingRequestId && !list.error) {
        fetchList(listUrl).catch(
          (error) => logger.debug("lists/poolsList/updater", "Updater", "interval list fetching error", error)
        );
      }
    });
    POOLS_LIST.forEach((listUrl) => {
      const list = lists[listUrl];
      if (!list || !list.current && !list.loadingRequestId && !list.error) {
        fetchList(
          listUrl,
          /* isUnsupportedList= */
          true
        ).catch(
          (error) => logger.debug("lists/poolsList/updater", "Updater", "interval list fetching error", error)
        );
      }
    });
  }, [dispatch, fetchList, lists, rehydrated]);
  useEffect(() => {
    if (!rehydrated || !lists) {
      return;
    }
    Object.keys(lists).forEach((listUrl) => {
      const list = lists[listUrl];
      if (list.current && list.pendingUpdate) {
        const bump = getVersionUpgrade(list.current.version, list.pendingUpdate.version);
        switch (bump) {
          case VersionUpgrade.NONE:
            throw new Error("unexpected no version bump");
          case VersionUpgrade.PATCH:
          case VersionUpgrade.MINOR:
          case VersionUpgrade.MAJOR:
            dispatch(acceptListUpdate(listUrl));
        }
      }
    });
  }, [dispatch, lists, rehydrated]);
  return null;
}
