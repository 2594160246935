"use strict";
import Modal from "components/Modal";
import { CurrencySearch } from "components/SearchModal/CurrencySearch";
import { DeprecatedCurrencySearch } from "components/SearchModal/DeprecatedCurrencySearch";
import TokenSafety from "components/TokenSafety";
import useLast from "hooks/useLast";
import { memo, useCallback, useEffect, useState } from "react";
import { useUserAddedTokens } from "state/user/userAddedTokens";
import { NAV_HEIGHT } from "theme";
import { AdaptiveWebModal } from "ui/src";
import { TOKEN_SELECTOR_WEB_MAX_WIDTH } from "uniswap/src/components/TokenSelector/TokenSelector";
import { FeatureFlags } from "uniswap/src/features/gating/flags";
import { useFeatureFlag } from "uniswap/src/features/gating/hooks";
import { CurrencyField } from "uniswap/src/types/currency";
var CurrencyModalView = /* @__PURE__ */ ((CurrencyModalView2) => {
  CurrencyModalView2[CurrencyModalView2["search"] = 0] = "search";
  CurrencyModalView2[CurrencyModalView2["importToken"] = 1] = "importToken";
  CurrencyModalView2[CurrencyModalView2["tokenSafety"] = 2] = "tokenSafety";
  return CurrencyModalView2;
})(CurrencyModalView || {});
export default memo(function CurrencySearchModal({
  isOpen,
  onDismiss,
  onCurrencySelect,
  selectedCurrency,
  otherSelectedCurrency,
  showCurrencyAmount = true,
  hideChainSwitch = false,
  currencySearchFilters,
  currencyField = CurrencyField.INPUT,
  operatedPools
}) {
  const [modalView, setModalView] = useState(0 /* search */);
  const lastOpen = useLast(isOpen);
  const userAddedTokens = useUserAddedTokens();
  const multichainFlagEnabled = useFeatureFlag(FeatureFlags.MultichainUX);
  useEffect(() => {
    if (isOpen && !lastOpen) {
      setModalView(0 /* search */);
    }
  }, [isOpen, lastOpen]);
  const showTokenSafetySpeedbump = (token) => {
    setWarningToken(token);
    setModalView(2 /* tokenSafety */);
  };
  const handleCurrencySelect = useCallback(
    (currency, hasWarning) => {
      if (!currencySearchFilters?.onlyDisplaySmartPools && hasWarning && currency.isToken && !userAddedTokens.find((token) => token.equals(currency))) {
        showTokenSafetySpeedbump(currency);
      } else {
        onCurrencySelect(currency);
        onDismiss();
      }
    },
    [onDismiss, onCurrencySelect, userAddedTokens, currencySearchFilters?.onlyDisplaySmartPools]
  );
  const [warningToken, setWarningToken] = useState();
  let content = null;
  switch (modalView) {
    case 0 /* search */:
      content = multichainFlagEnabled && !currencySearchFilters?.onlyDisplaySmartPools ? <CurrencySearch
        currencyField={currencyField}
        hideChainSwitch={hideChainSwitch}
        onCurrencySelect={onCurrencySelect}
        onDismiss={onDismiss}
      /> : <DeprecatedCurrencySearch
        isOpen={isOpen}
        onDismiss={onDismiss}
        onCurrencySelect={handleCurrencySelect}
        selectedCurrency={selectedCurrency}
        otherSelectedCurrency={otherSelectedCurrency}
        showCurrencyAmount={showCurrencyAmount}
        hideChainSwitch={hideChainSwitch}
        filters={currencySearchFilters}
        operatedPools={operatedPools}
      />;
      break;
    case 2 /* tokenSafety */:
      if (warningToken) {
        content = <TokenSafety
          token0={warningToken}
          onContinue={() => handleCurrencySelect(warningToken)}
          onCancel={() => setModalView(0 /* search */)}
          showCancel={true}
        />;
      }
      break;
  }
  return multichainFlagEnabled ? <AdaptiveWebModal
    isOpen={isOpen}
    onClose={onDismiss}
    maxHeight={modalView === 2 /* tokenSafety */ ? 400 : 700}
    maxWidth={TOKEN_SELECTOR_WEB_MAX_WIDTH}
    px={0}
    py={0}
    flex={1}
    $sm={{ height: `calc(100dvh - ${NAV_HEIGHT}px)` }}
  >{content}</AdaptiveWebModal> : <Modal
    isOpen={isOpen}
    onDismiss={onDismiss}
    height="90vh"
    maxHeight={modalView === 2 /* tokenSafety */ ? 400 : 700}
  >{content}</Modal>;
});
