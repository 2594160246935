"use strict";
import { Percent } from "@uniswap/sdk-core";
import { exploreSearchStringAtom } from "components/Tokens/state";
import { chainIdToBackendChain } from "constants/chains";
import { BIPS_BASE } from "constants/misc";
import { OrderDirection } from "graphql/data/util";
import useIsWindowVisible from "hooks/useIsWindowVisible";
import { useAtomValue } from "jotai/utils";
import { useMemo } from "react";
import {
  useTopV2PairsQuery,
  useTopV3PoolsQuery
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
export function sortPools(pools, sortState) {
  return pools.sort((a, b) => {
    switch (sortState.sortBy) {
      case "Transactions" /* TxCount */:
        return sortState.sortDirection === OrderDirection.Desc ? b.txCount - a.txCount : a.txCount - b.txCount;
      case "1 day volume" /* Volume24h */:
        return sortState.sortDirection === OrderDirection.Desc ? b.volume24h - a.volume24h : a.volume24h - b.volume24h;
      case "7 day volume" /* VolumeWeek */:
        return sortState.sortDirection === OrderDirection.Desc ? b.volumeWeek - a.volumeWeek : a.volumeWeek - b.volumeWeek;
      case "1 day APR" /* OneDayApr */:
        return sortState.sortDirection === OrderDirection.Desc ? b.oneDayApr.greaterThan(a.oneDayApr) ? 1 : -1 : a.oneDayApr.greaterThan(b.oneDayApr) ? 1 : -1;
      default:
        return sortState.sortDirection === OrderDirection.Desc ? b.tvl - a.tvl : a.tvl - b.tvl;
    }
  });
}
export function calculateOneDayApr(volume24h, tvl, feeTier) {
  if (!volume24h || !feeTier || !tvl || !Math.round(tvl)) {
    return new Percent(0);
  }
  return new Percent(Math.round(volume24h * (feeTier / (BIPS_BASE * 100))), Math.round(tvl));
}
export const V2_BIPS = 3e3;
export var PoolSortFields = /* @__PURE__ */ ((PoolSortFields2) => {
  PoolSortFields2["TVL"] = "TVL";
  PoolSortFields2["Volume24h"] = "1 day volume";
  PoolSortFields2["VolumeWeek"] = "7 day volume";
  PoolSortFields2["OneDayApr"] = "1 day APR";
  PoolSortFields2["TxCount"] = "Transactions";
  return PoolSortFields2;
})(PoolSortFields || {});
function useFilteredPools(pools) {
  const filterString = useAtomValue(exploreSearchStringAtom);
  const lowercaseFilterString = useMemo(() => filterString.toLowerCase(), [filterString]);
  return useMemo(
    () => pools.filter((pool) => {
      const addressIncludesFilterString = pool.hash.toLowerCase().includes(lowercaseFilterString);
      const token0IncludesFilterString = pool.token0?.symbol?.toLowerCase().includes(lowercaseFilterString);
      const token1IncludesFilterString = pool.token1?.symbol?.toLowerCase().includes(lowercaseFilterString);
      const token0HashIncludesFilterString = pool.token0?.address?.toLowerCase().includes(lowercaseFilterString);
      const token1HashIncludesFilterString = pool.token1?.address?.toLowerCase().includes(lowercaseFilterString);
      const poolName = `${pool.token0?.symbol}/${pool.token1?.symbol}`.toLowerCase();
      const poolNameIncludesFilterString = poolName.includes(lowercaseFilterString);
      return token0IncludesFilterString || token1IncludesFilterString || addressIncludesFilterString || token0HashIncludesFilterString || token1HashIncludesFilterString || poolNameIncludesFilterString;
    }),
    [lowercaseFilterString, pools]
  );
}
export function useTopPools(sortState, chainId) {
  const isWindowVisible = useIsWindowVisible();
  const {
    loading: loadingV3,
    error: errorV3,
    data: dataV3
  } = useTopV3PoolsQuery({
    variables: { first: 100, chain: chainIdToBackendChain({ chainId, withFallback: true }) },
    skip: !isWindowVisible
  });
  const {
    loading: loadingV2,
    error: errorV2,
    data: dataV2
  } = useTopV2PairsQuery({
    variables: { first: 100, chain: chainIdToBackendChain({ chainId, withFallback: true }) },
    skip: !isWindowVisible || !chainId
  });
  const loading = loadingV3 || loadingV2;
  const unfilteredPools = useMemo(() => {
    const topV3Pools = dataV3?.topV3Pools?.map((pool) => {
      return {
        hash: pool.address,
        token0: pool.token0,
        token1: pool.token1,
        txCount: pool.txCount,
        tvl: pool.totalLiquidity?.value,
        volume24h: pool.volume24h?.value,
        volumeWeek: pool.volumeWeek?.value,
        oneDayApr: calculateOneDayApr(pool.volume24h?.value, pool.totalLiquidity?.value, pool.feeTier),
        feeTier: pool.feeTier,
        protocolVersion: pool.protocolVersion
      };
    }) ?? [];
    const topV2Pairs = dataV2?.topV2Pairs?.map((pool) => {
      return {
        hash: pool.address,
        token0: pool.token0,
        token1: pool.token1,
        txCount: pool.txCount,
        tvl: pool.totalLiquidity?.value,
        volume24h: pool.volume24h?.value,
        volumeWeek: pool.volumeWeek?.value,
        oneDayApr: calculateOneDayApr(pool.volume24h?.value, pool.totalLiquidity?.value, V2_BIPS),
        feeTier: V2_BIPS,
        protocolVersion: pool.protocolVersion
      };
    }) ?? [];
    return sortPools([...topV3Pools, ...topV2Pairs], sortState);
  }, [dataV2?.topV2Pairs, dataV3?.topV3Pools, sortState]);
  const filteredPools = useFilteredPools(unfilteredPools).slice(0, 100);
  return { topPools: filteredPools, loading, errorV3, errorV2 };
}
