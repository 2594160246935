"use strict";
import { BigNumber } from "@ethersproject/bignumber";
import { CurrencyAmount } from "@uniswap/sdk-core";
import { useV3NFTPositionManagerContract } from "hooks/useContract";
import { useSingleCallResult } from "lib/hooks/multicall";
import useBlockNumber from "lib/hooks/useBlockNumber";
import { useEffect, useState } from "react";
import { unwrappedToken } from "utils/unwrappedToken";
const MAX_UINT128 = BigNumber.from(2).pow(128).sub(1);
export function useV3PositionFees(pool, tokenId, asWETH = false) {
  const positionManager = useV3NFTPositionManagerContract(false);
  const owner = useSingleCallResult(tokenId ? positionManager : null, "ownerOf", [tokenId]).result?.[0];
  const tokenIdHexString = tokenId?.toHexString();
  const latestBlockNumber = useBlockNumber();
  const [amounts, setAmounts] = useState();
  useEffect(() => {
    ;
    (async function getFees() {
      if (positionManager && tokenIdHexString && owner) {
        try {
          const results = await positionManager.callStatic.collect(
            {
              tokenId: tokenIdHexString,
              recipient: owner,
              // some tokens might fail if transferred to address(0)
              amount0Max: MAX_UINT128,
              amount1Max: MAX_UINT128
            },
            { from: owner }
            // need to simulate the call as the owner
          );
          setAmounts([results.amount0, results.amount1]);
        } catch {
        }
      }
    })();
  }, [positionManager, tokenIdHexString, owner, latestBlockNumber]);
  if (pool && amounts) {
    return [
      CurrencyAmount.fromRawAmount(asWETH ? pool.token0 : unwrappedToken(pool.token0), amounts[0].toString()),
      CurrencyAmount.fromRawAmount(asWETH ? pool.token1 : unwrappedToken(pool.token1), amounts[1].toString())
    ];
  } else {
    return [void 0, void 0];
  }
}
