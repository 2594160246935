"use strict";
export var TransactionType = /* @__PURE__ */ ((TransactionType2) => {
  TransactionType2[TransactionType2["APPROVAL"] = 0] = "APPROVAL";
  TransactionType2[TransactionType2["SWAP"] = 1] = "SWAP";
  TransactionType2[TransactionType2["DEPOSIT_LIQUIDITY_STAKING"] = 2] = "DEPOSIT_LIQUIDITY_STAKING";
  TransactionType2[TransactionType2["WITHDRAW_LIQUIDITY_STAKING"] = 3] = "WITHDRAW_LIQUIDITY_STAKING";
  TransactionType2[TransactionType2["CLAIM"] = 4] = "CLAIM";
  TransactionType2[TransactionType2["VOTE"] = 5] = "VOTE";
  TransactionType2[TransactionType2["DELEGATE"] = 6] = "DELEGATE";
  TransactionType2[TransactionType2["WRAP"] = 7] = "WRAP";
  TransactionType2[TransactionType2["CREATE_V3_POOL"] = 8] = "CREATE_V3_POOL";
  TransactionType2[TransactionType2["ADD_LIQUIDITY_V3_POOL"] = 9] = "ADD_LIQUIDITY_V3_POOL";
  TransactionType2[TransactionType2["ADD_LIQUIDITY_V2_POOL"] = 10] = "ADD_LIQUIDITY_V2_POOL";
  TransactionType2[TransactionType2["MIGRATE_LIQUIDITY_V3"] = 11] = "MIGRATE_LIQUIDITY_V3";
  TransactionType2[TransactionType2["COLLECT_FEES"] = 12] = "COLLECT_FEES";
  TransactionType2[TransactionType2["REMOVE_LIQUIDITY_V3"] = 13] = "REMOVE_LIQUIDITY_V3";
  TransactionType2[TransactionType2["SUBMIT_PROPOSAL"] = 14] = "SUBMIT_PROPOSAL";
  TransactionType2[TransactionType2["QUEUE"] = 15] = "QUEUE";
  TransactionType2[TransactionType2["EXECUTE"] = 16] = "EXECUTE";
  TransactionType2[TransactionType2["BUY"] = 17] = "BUY";
  TransactionType2[TransactionType2["SEND"] = 18] = "SEND";
  TransactionType2[TransactionType2["RECEIVE"] = 19] = "RECEIVE";
  TransactionType2[TransactionType2["MINT"] = 20] = "MINT";
  TransactionType2[TransactionType2["BURN"] = 21] = "BURN";
  TransactionType2[TransactionType2["BORROW"] = 22] = "BORROW";
  TransactionType2[TransactionType2["REPAY"] = 23] = "REPAY";
  TransactionType2[TransactionType2["DEPLOY"] = 24] = "DEPLOY";
  TransactionType2[TransactionType2["CANCEL"] = 25] = "CANCEL";
  TransactionType2[TransactionType2["LIMIT"] = 26] = "LIMIT";
  TransactionType2[TransactionType2["SELL"] = 27] = "SELL";
  TransactionType2[TransactionType2["SET_SPREAD"] = 28] = "SET_SPREAD";
  TransactionType2[TransactionType2["SET_LOCKUP"] = 29] = "SET_LOCKUP";
  TransactionType2[TransactionType2["SET_VALUE"] = 30] = "SET_VALUE";
  return TransactionType2;
})(TransactionType || {});
