"use strict";
import { Protocol } from "@uniswap/router-sdk";
import Column from "components/Column";
import UniswapXBrandMark from "components/Logo/UniswapXBrandMark";
import QuestionHelper from "components/QuestionHelper";
import Row, { RowBetween } from "components/Row";
import Toggle from "components/Toggle";
import { isUniswapXSupportedChain } from "constants/chains";
import { atom, useAtom } from "jotai";
import styled from "lib/styled-components";
import { useCallback } from "react";
import { RouterPreference } from "state/routing/types";
import { ExternalLink, ThemedText } from "theme/components";
import { uniswapUrls } from "uniswap/src/constants/urls";
import { Trans, t } from "uniswap/src/i18n";
const LabelWrapper = styled(Column)`
  height: 100%;
  justify-content: center;
`;
var RoutePreferenceOption = /* @__PURE__ */ ((RoutePreferenceOption2) => {
  RoutePreferenceOption2["Optimal"] = "Optimal";
  RoutePreferenceOption2["UniswapX"] = "UniswapX";
  RoutePreferenceOption2["v3"] = "v3";
  RoutePreferenceOption2["v2"] = "v2";
  return RoutePreferenceOption2;
})(RoutePreferenceOption || {});
const DEFAULT_ROUTE_PREFERENCE_OPTIONS = {
  ["Optimal" /* Optimal */]: true,
  ["UniswapX" /* UniswapX */]: false,
  ["v3" /* v3 */]: false,
  ["v2" /* v2 */]: false
};
const DEFAULT_ROUTING_PREFERENCE = {
  router: RouterPreference.X,
  protocols: [Protocol.V2, Protocol.V3, Protocol.MIXED]
};
export const routingPreferencesAtom = atom(DEFAULT_ROUTING_PREFERENCE);
const routePreferenceOptionsAtom = atom(DEFAULT_ROUTE_PREFERENCE_OPTIONS);
function UniswapXPreferenceLabel() {
  return <Row>
    <UniswapXBrandMark />
    <QuestionHelper
      text={<>
        <Trans i18nKey="routing.aggregateLiquidity" />
        {" "}
        <ExternalLink href={uniswapUrls.helpArticleUrls.uniswapXInfo}><Trans i18nKey="common.button.learn" /></ExternalLink>
      </>}
      placement="right"
    />
  </Row>;
}
const ROUTE_PREFERENCE_TO_LABEL = {
  ["Optimal" /* Optimal */]: t("common.defaultTradeOptions"),
  ["UniswapX" /* UniswapX */]: <UniswapXPreferenceLabel />,
  ["v3" /* v3 */]: t("pool.v3"),
  ["v2" /* v2 */]: t("pool.v2")
};
function RoutePreferenceToggle({
  preference,
  isActive,
  text,
  subheading,
  disabled,
  toggle
}) {
  return <RowBetween gap="md" padding="2px 0px" align="start">
    <LabelWrapper gap="xs">
      <ThemedText.BodyPrimary>{ROUTE_PREFERENCE_TO_LABEL[preference]}</ThemedText.BodyPrimary>
      {text && <ThemedText.BodySmall color="neutral2">{text}</ThemedText.BodySmall>}
      {subheading && <ThemedText.BodySmall color="neutral2">{subheading}</ThemedText.BodySmall>}
    </LabelWrapper>
    <Toggle id={`route-preference-toggle-${preference}`} isActive={isActive} disabled={disabled} toggle={toggle} />
  </RowBetween>;
}
export default function MultipleRoutingOptions({ chainId }) {
  const [routePreferenceOptions, setRoutePreferenceOptions] = useAtom(routePreferenceOptionsAtom);
  const [, setRoutingPreferences] = useAtom(routingPreferencesAtom);
  const shouldDisableProtocolOptionToggle = !routePreferenceOptions["v2" /* v2 */] || !routePreferenceOptions["v3" /* v3 */];
  const uniswapXSupportedChain = chainId && isUniswapXSupportedChain(chainId);
  const handleSetRoutePreferenceOptions = useCallback(
    (options) => {
      if (options["Optimal" /* Optimal */]) {
        setRoutePreferenceOptions(options);
        setRoutingPreferences({
          router: RouterPreference.X,
          protocols: [Protocol.V2, Protocol.V3, Protocol.MIXED]
        });
        return;
      }
      const routingPreferences = {
        router: options["UniswapX" /* UniswapX */] ? RouterPreference.X : RouterPreference.API,
        protocols: []
      };
      if (options["v2" /* v2 */] && options["v3" /* v3 */]) {
        routingPreferences.protocols = [Protocol.V2, Protocol.V3, Protocol.MIXED];
      } else if (options["v2" /* v2 */]) {
        routingPreferences.protocols = [Protocol.V2];
      } else if (options["v3" /* v3 */]) {
        routingPreferences.protocols = [Protocol.V3];
      }
      setRoutePreferenceOptions(options);
      setRoutingPreferences(routingPreferences);
    },
    [setRoutePreferenceOptions, setRoutingPreferences]
  );
  const handleRoutePreferenceToggle = useCallback(
    (toggledPreferenceOption) => {
      if (toggledPreferenceOption === "Optimal" /* Optimal */) {
        routePreferenceOptions["Optimal" /* Optimal */] ? handleSetRoutePreferenceOptions({
          ["Optimal" /* Optimal */]: false,
          ["UniswapX" /* UniswapX */]: true,
          ["v2" /* v2 */]: true,
          ["v3" /* v3 */]: true
        }) : handleSetRoutePreferenceOptions({
          ["Optimal" /* Optimal */]: true,
          ["UniswapX" /* UniswapX */]: false,
          ["v2" /* v2 */]: false,
          ["v3" /* v3 */]: false
        });
        return;
      }
      handleSetRoutePreferenceOptions({
        ...routePreferenceOptions,
        [toggledPreferenceOption]: !routePreferenceOptions[toggledPreferenceOption]
      });
    },
    [handleSetRoutePreferenceOptions, routePreferenceOptions]
  );
  return <Column gap="sm">
    <RoutePreferenceToggle
      preference={"Optimal" /* Optimal */}
      isActive={routePreferenceOptions["Optimal" /* Optimal */]}
      text={<Trans i18nKey="routing.cheapest" />}
      subheading={routePreferenceOptions["Optimal" /* Optimal */] && uniswapXSupportedChain && <Row gap="xs">
        <Trans i18nKey="common.includes" />
        <UniswapXBrandMark />
      </Row>}
      toggle={() => handleRoutePreferenceToggle("Optimal" /* Optimal */)}
    />
    {!routePreferenceOptions["Optimal" /* Optimal */] && ["UniswapX" /* UniswapX */, "v3" /* v3 */, "v2" /* v2 */].map((preference) => {
      if (preference === "UniswapX" /* UniswapX */ && !uniswapXSupportedChain) {
        return null;
      }
      return <RoutePreferenceToggle
        key={preference}
        preference={preference}
        isActive={routePreferenceOptions[preference]}
        disabled={preference !== "UniswapX" /* UniswapX */ && routePreferenceOptions[preference] && shouldDisableProtocolOptionToggle}
        toggle={() => handleRoutePreferenceToggle(preference)}
      />;
    })}
  </Column>;
}
