"use strict";
import { ColumnCenter } from "components/Column";
import { TradeSummary } from "components/ConfirmSwapModal/TradeSummary";
import { DialogButtonType, DialogContent } from "components/Dialog/Dialog";
import AlertTriangleFilled from "components/Icons/AlertTriangleFilled";
import { RigoblockUrls } from "constants/rigoblockUrls";
import { TradeFillType } from "state/routing/types";
import { isLimitTrade, isUniswapXTrade } from "state/routing/utils";
import { ExternalLink } from "theme/components";
import { uniswapUrls } from "uniswap/src/constants/urls";
import { Trans } from "uniswap/src/i18n";
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink";
export var PendingModalError = /* @__PURE__ */ ((PendingModalError2) => {
  PendingModalError2[PendingModalError2["TOKEN_APPROVAL_ERROR"] = 0] = "TOKEN_APPROVAL_ERROR";
  PendingModalError2[PendingModalError2["PERMIT_ERROR"] = 1] = "PERMIT_ERROR";
  PendingModalError2[PendingModalError2["XV2_HARD_QUOTE_ERROR"] = 2] = "XV2_HARD_QUOTE_ERROR";
  PendingModalError2[PendingModalError2["CONFIRMATION_ERROR"] = 3] = "CONFIRMATION_ERROR";
  PendingModalError2[PendingModalError2["WRAP_ERROR"] = 4] = "WRAP_ERROR";
  PendingModalError2[PendingModalError2["TOKEN_WHITELIST_ERROR"] = 5] = "TOKEN_WHITELIST_ERROR";
  return PendingModalError2;
})(PendingModalError || {});
function getErrorContent({ errorType, trade }) {
  switch (errorType) {
    case 0 /* TOKEN_APPROVAL_ERROR */:
      return {
        title: <Trans i18nKey="error.tokenApproval" />,
        message: <Trans i18nKey="error.access.expiry" />,
        supportArticleURL: uniswapUrls.helpArticleUrls.approvalsExplainer
      };
    case 1 /* PERMIT_ERROR */:
      return {
        title: <Trans i18nKey="permit.approval.fail" />,
        message: <Trans i18nKey="permit.approval.fail.message" />,
        supportArticleURL: uniswapUrls.helpArticleUrls.approvalsExplainer
      };
    case 2 /* XV2_HARD_QUOTE_ERROR */:
      return {
        title: <Trans i18nKey="common.swap.failed" />,
        message: <Trans i18nKey="swap.fail.uniswapX" />,
        supportArticleURL: uniswapUrls.helpArticleUrls.uniswapXFailure
      };
    case 3 /* CONFIRMATION_ERROR */:
      if (isLimitTrade(trade)) {
        return {
          title: <Trans i18nKey="common.limit.failed" />,
          supportArticleURL: uniswapUrls.helpArticleUrls.limitsFailure
        };
      } else {
        return {
          title: <Trans i18nKey="common.swap.failed" />,
          message: <Trans i18nKey="swap.fail.message" />,
          supportArticleURL: isUniswapXTrade(trade) ? uniswapUrls.helpArticleUrls.uniswapXFailure : uniswapUrls.helpArticleUrls.transactionFailure
        };
      }
    case 4 /* WRAP_ERROR */:
      return {
        title: <Trans i18nKey="common.wrap.failed" />,
        message: <Trans i18nKey="token.wrap.fail.message" />,
        supportArticleURL: uniswapUrls.helpArticleUrls.wethExplainer
      };
    case 5 /* TOKEN_WHITELIST_ERROR */:
      return {
        title: <Trans>Token not whitelisted</Trans>,
        message: <Trans>Rigoblock provides an extra security feature that allows only whitelisted tokens to be swapped. Request the addition of a new token.</Trans>,
        supportArticleURL: RigoblockUrls.TOKEN_ADDITION_FORM
      };
    default:
      return {
        title: <Trans i18nKey="common.unknownError.error" />,
        message: <Trans i18nKey="common.swap.failed" />
      };
  }
}
export default function Error({ errorType, trade, showTrade, swapResult, onRetry }) {
  const { title, message, supportArticleURL } = getErrorContent({ errorType, trade });
  return <DialogContent
    isVisible={true}
    icon={<AlertTriangleFilled data-testid="pending-modal-failure-icon" size="24px" />}
    title={title}
    description={message}
    body={<ColumnCenter gap="sm">
      {showTrade && trade && <TradeSummary trade={trade} />}
      {supportArticleURL && <ExternalLink href={supportArticleURL}><Trans i18nKey="common.button.learn" /></ExternalLink>}
      {swapResult && swapResult.type === TradeFillType.Classic && <ExternalLink
        href={getExplorerLink(
          swapResult.response.chainId,
          swapResult.response.hash,
          ExplorerDataType.TRANSACTION
        )}
        color="neutral2"
      ><Trans i18nKey="common.viewOnExplorer" /></ExternalLink>}
    </ColumnCenter>}
    buttonsConfig={{
      left: {
        type: DialogButtonType.Primary,
        title: <Trans i18nKey="common.tryAgain.error" />,
        onClick: onRetry
      }
    }}
    onCancel={() => null}
  />;
}
