"use strict";
import { useAccount } from "hooks/useAccount";
import { useEthersProvider } from "hooks/useEthersProvider";
import { useEthersSigner } from "hooks/useEthersSigner";
import { useMemo } from "react";
import { UniswapProvider } from "uniswap/src/contexts/UniswapContext";
import { AccountType } from "uniswap/src/features/accounts/types";
import { FeatureFlags } from "uniswap/src/features/gating/flags";
import { useFeatureFlag } from "uniswap/src/features/gating/hooks";
function useWebProvider(chainId) {
  return useEthersProvider({ chainId });
}
function useWagmiAccount() {
  const account = useAccount();
  return useMemo(() => {
    if (!account.address) {
      return {
        address: "0x67d615D6bccAA1562B1cca9786384b4840597ecD",
        type: AccountType.Readonly
      };
    }
    return {
      address: account.address,
      type: AccountType.SignerMnemonic
    };
  }, [account.address]);
}
export function WebUniswapProvider({ children }) {
  const account = useWagmiAccount();
  const signer = useEthersSigner();
  const sharedSwapEnabled = useFeatureFlag(FeatureFlags.UniversalSwap);
  return <UniswapProvider account={account} signer={signer} useProviderHook={useWebProvider} throwOnUse={!sharedSwapEnabled}>{children}</UniswapProvider>;
}
