"use strict";
import { NATIVE_CHAIN_ID, nativeOnChain } from "constants/tokens";
import { supportedChainIdFromGQLChain } from "graphql/data/util";
import { useAtom } from "jotai";
import { atomWithStorage, useAtomValue } from "jotai/utils";
import { useCallback, useMemo } from "react";
import { UNIVERSE_CHAIN_INFO } from "uniswap/src/constants/chains";
import {
  Chain,
  useRecentlySearchedAssetsQuery
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { toGraphQLChain } from "uniswap/src/features/chains/utils";
import { SearchResultType } from "uniswap/src/features/search/SearchResult";
import { logger } from "utilities/src/logger/logger";
import { getNativeTokenDBAddress } from "utils/nativeTokens";
const NATIVE_QUERY_ADDRESS_INPUT = null;
function getQueryAddress(chain) {
  return getNativeTokenDBAddress(chain) ?? NATIVE_QUERY_ADDRESS_INPUT;
}
function getNativeQueryAddress(chain) {
  return `NATIVE-${chain}`;
}
export const recentlySearchedAssetsAtom = atomWithStorage("recentlySearchedAssetsV3", []);
export function useAddRecentlySearchedCurrency() {
  const [searchHistory, updateSearchHistory] = useAtom(recentlySearchedAssetsAtom);
  return useCallback(
    (currencyInfo) => {
      const newHistory = searchHistory.filter((oldCurrency) => {
        if (oldCurrency.isNft) {
          return true;
        }
        if (currencyInfo.currency.isToken) {
          return !(oldCurrency.address === currencyInfo.currency.address && oldCurrency.chainId === currencyInfo.currency.chainId);
        } else {
          return oldCurrency.chainId !== currencyInfo.currency.chainId;
        }
      });
      newHistory.unshift({
        type: SearchResultType.Token,
        chain: toGraphQLChain(currencyInfo.currency.chainId) ?? Chain.Ethereum,
        chainId: currencyInfo.currency.chainId,
        address: currencyInfo.currency.isToken ? currencyInfo.currency.address : UNIVERSE_CHAIN_INFO[currencyInfo.currency.chainId].nativeCurrency.address,
        name: currencyInfo.currency.name ?? null,
        symbol: currencyInfo.currency.symbol ?? "",
        logoUrl: currencyInfo.logoUrl ?? null,
        safetyLevel: currencyInfo.safetyLevel ?? null,
        isToken: currencyInfo.currency.isToken,
        isNative: currencyInfo.currency.isNative
      });
      updateSearchHistory(newHistory);
    },
    [searchHistory, updateSearchHistory]
  );
}
export function useAddRecentlySearchedAsset() {
  const [searchHistory, updateSearchHistory] = useAtom(recentlySearchedAssetsAtom);
  return useCallback(
    (asset) => {
      const address = asset.isNative ? UNIVERSE_CHAIN_INFO[asset.chainId].nativeCurrency.address : asset.address;
      const newHistory = searchHistory.filter(
        (oldAsset) => !(oldAsset.address === address && oldAsset.chain === asset.chain)
      );
      newHistory.unshift({
        ...asset,
        address
      });
      updateSearchHistory(newHistory);
    },
    [searchHistory, updateSearchHistory]
  );
}
export function useRecentlySearchedAssets() {
  const history = useAtomValue(recentlySearchedAssetsAtom);
  const shortenedHistory = useMemo(() => history.slice(0, 4), [history]);
  const { data: queryData, loading } = useRecentlySearchedAssetsQuery({
    variables: {
      collectionAddresses: shortenedHistory.filter((asset) => asset.isNft).map((asset) => asset.address),
      contracts: shortenedHistory.filter((asset) => !asset.isNft).map((token) => ({
        address: token.isNative ? getQueryAddress(token.chain) : token.address,
        chain: token.chain
      }))
    }
  });
  const data = useMemo(() => {
    if (shortenedHistory.length === 0) {
      return [];
    } else if (!queryData) {
      return void 0;
    }
    const resultsMap = {};
    const queryCollections = queryData?.nftCollections?.edges.map((edge) => edge.node);
    const collections = queryCollections?.map(
      (queryCollection) => {
        return {
          address: queryCollection.nftContracts?.[0]?.address ?? "",
          isVerified: queryCollection?.isVerified,
          name: queryCollection?.name,
          stats: {
            floor_price: queryCollection?.markets?.[0]?.floorPrice?.value,
            total_supply: queryCollection?.numAssets
          },
          imageUrl: queryCollection?.image?.url ?? ""
        };
      },
      [queryCollections]
    );
    collections?.forEach((collection) => resultsMap[collection.address] = collection);
    queryData.tokens?.filter(Boolean).forEach((token) => {
      if (token) {
        resultsMap[token.address ?? getNativeQueryAddress(token.chain)] = token;
      }
    });
    const data2 = [];
    shortenedHistory.forEach((asset) => {
      if (asset.isNative) {
        const chain = supportedChainIdFromGQLChain(asset.chain);
        if (!chain) {
          logger.error(new Error("Invalid chain retrieved from Search Token/Collection Query"), {
            tags: {
              file: "RecentlySearchedAssets",
              function: "useRecentlySearchedAssets"
            },
            extra: { asset }
          });
          return;
        }
        const native = nativeOnChain(chain);
        const queryAddress = getQueryAddress(asset.chain)?.toLowerCase() ?? getNativeQueryAddress(asset.chain);
        const result = resultsMap[queryAddress];
        if (result) {
          data2.push({ ...result, address: NATIVE_CHAIN_ID, ...native });
        }
      } else {
        const result = resultsMap[asset.address];
        if (result) {
          data2.push(result);
        }
      }
    });
    return data2;
  }, [queryData, shortenedHistory]);
  return { data, loading };
}
