"use strict";
import { isBetaEnv, isProdEnv } from "utilities/src/environment";
export function isAppRigoblockCom({ hostname }) {
  return hostname === "app.rigoblock.com";
}
export function isAppRigoblockStagingCom({ hostname }) {
  return hostname === "staging.rigoblock.com";
}
export function isBrowserRouterEnabled() {
  if (isProdEnv()) {
    if (isAppRigoblockCom(window.location) || isAppRigoblockStagingCom(window.location) || isLocalhost(window.location)) {
      return true;
    }
    return false;
  }
  return true;
}
function isLocalhost({ hostname }) {
  return hostname === "localhost";
}
export function isRemoteReportingEnabled() {
  if (isBetaEnv() && !isAppRigoblockStagingCom(window.location)) {
    return false;
  }
  if (isProdEnv()) {
    return false;
  }
  return process.env.REACT_APP_SENTRY_ENABLED === "false";
}
