"use strict";
function cumulativeBuildUp(arr) {
  let sum = 0;
  return arr.map((value) => {
    const newValue = sum + value;
    sum = newValue;
    return newValue;
  });
}
export class StackedAreaSeriesRenderer {
  _data = null;
  _options = null;
  draw(target, priceConverter) {
    target.useBitmapCoordinateSpace((scope) => this._drawImpl(scope, priceConverter));
  }
  update(data, options) {
    this._data = data;
    this._options = options;
  }
  _drawImpl(renderingScope, priceToCoordinate) {
    if (this._data === null || this._data.bars.length === 0 || this._data.visibleRange === null || this._options === null) {
      return;
    }
    const ctx = renderingScope.context;
    const options = this._options;
    const bars = this._data.bars.map((bar) => {
      return {
        x: bar.x,
        ys: cumulativeBuildUp(bar.originalData.values).map((value) => priceToCoordinate(value) ?? 0)
      };
    });
    const zeroY = priceToCoordinate(0) ?? 0;
    const { linesMeshed, hoverInfo } = this._createLinePaths(
      bars,
      this._data.visibleRange,
      renderingScope,
      zeroY * renderingScope.verticalPixelRatio,
      options.hoveredLogicalIndex
    );
    const areaPaths = this._createAreas(linesMeshed);
    const colorsCount = options.colors.length;
    areaPaths.forEach((areaPath, index) => {
      if (areaPaths.length === 1) {
        ctx.globalAlpha = 0.12;
      } else {
        const hasHoveredIndex = options.hoveredLogicalIndex !== void 0 && options.hoveredLogicalIndex !== -1;
        ctx.globalAlpha = hasHoveredIndex ? 0.24 : 1;
      }
      ctx.fillStyle = options.colors[index % colorsCount];
      ctx.fill(areaPath);
    });
    ctx.globalAlpha = 1;
    ctx.lineWidth = options.lineWidth * renderingScope.verticalPixelRatio;
    ctx.lineJoin = "round";
    linesMeshed.toReversed().forEach((linePath, index) => {
      const unreversedIndex = linesMeshed.length - index;
      const color = options.colors[unreversedIndex % colorsCount];
      ctx.strokeStyle = color;
      ctx.fillStyle = color;
      if (index !== linesMeshed.length - 1) {
        ctx.beginPath();
        ctx.strokeStyle = color;
        ctx.stroke(linePath.path);
      }
      const hoverY = hoverInfo.points[index - 1];
      ctx.globalCompositeOperation = "destination-out";
      ctx.beginPath();
      ctx.arc(hoverInfo.x, hoverY, 5 * renderingScope.verticalPixelRatio, 0, 2 * Math.PI);
      ctx.fill();
      ctx.globalCompositeOperation = "source-over";
      ctx.beginPath();
      ctx.arc(hoverInfo.x, hoverY, 3 * renderingScope.verticalPixelRatio, 0, 2 * Math.PI);
      ctx.fill();
      ctx.globalAlpha = 0.2;
      ctx.beginPath();
      ctx.arc(hoverInfo.x, hoverY, 8 * renderingScope.verticalPixelRatio, 0, 2 * Math.PI);
      ctx.fill();
      ctx.globalAlpha = 0.3;
      ctx.beginPath();
      ctx.arc(hoverInfo.x, hoverY, 12 * renderingScope.verticalPixelRatio, 0, 2 * Math.PI);
      ctx.fill();
      ctx.globalAlpha = 1;
    });
  }
  /** Builds canvas line paths based on input data  */
  _createLinePaths(bars, visibleRange, renderingScope, zeroY, hoveredIndex) {
    const { horizontalPixelRatio, verticalPixelRatio } = renderingScope;
    const oddLines = [];
    const evenLines = [];
    let firstBar = true;
    const hoverInfo = { points: new Array(), x: 0 };
    for (let i = visibleRange.from - 1; i < visibleRange.to + 1; i++) {
      if (i >= bars.length || i < 0) {
        continue;
      }
      const stack = bars[i];
      let lineIndex = 0;
      stack.ys.forEach((yMedia, index) => {
        if (index % 2 !== 0) {
          return;
        }
        const x = stack.x * horizontalPixelRatio;
        const y = yMedia * verticalPixelRatio;
        if (i === hoveredIndex) {
          hoverInfo.points[index] = y;
          hoverInfo.x = x;
        }
        if (firstBar) {
          oddLines[lineIndex] = {
            path: new Path2D(),
            first: { x, y },
            last: { x, y }
          };
          oddLines[lineIndex].path.moveTo(x, y);
        } else {
          oddLines[lineIndex].path.lineTo(x, y);
          oddLines[lineIndex].last.x = x;
          oddLines[lineIndex].last.y = y;
        }
        lineIndex += 1;
      });
      firstBar = false;
    }
    firstBar = true;
    for (let i = visibleRange.to + 1; i >= visibleRange.from - 1; i--) {
      if (i >= bars.length || i < 0) {
        continue;
      }
      const stack = bars[i];
      let lineIndex = 0;
      stack.ys.forEach((yMedia, index) => {
        if (index % 2 === 0) {
          return;
        }
        const x = stack.x * horizontalPixelRatio;
        const y = yMedia * verticalPixelRatio;
        if (i === hoveredIndex) {
          hoverInfo.points[index] = y;
          hoverInfo.x = x;
        }
        if (firstBar) {
          evenLines[lineIndex] = {
            path: new Path2D(),
            first: { x, y },
            last: { x, y }
          };
          evenLines[lineIndex].path.moveTo(x, y);
        } else {
          evenLines[lineIndex].path.lineTo(x, y);
          evenLines[lineIndex].last.x = x;
          evenLines[lineIndex].last.y = y;
        }
        lineIndex += 1;
      });
      firstBar = false;
    }
    const baseLine = {
      path: new Path2D(),
      first: { x: oddLines[0].last.x, y: zeroY },
      last: { x: oddLines[0].first.x, y: zeroY }
    };
    baseLine.path.moveTo(oddLines[0].last.x, zeroY);
    baseLine.path.lineTo(oddLines[0].first.x, zeroY);
    const linesMeshed = [baseLine];
    for (let i = 0; i < oddLines.length; i++) {
      linesMeshed.push(oddLines[i]);
      if (i < evenLines.length) {
        linesMeshed.push(evenLines[i]);
      }
    }
    return { linesMeshed, hoverInfo };
  }
  /** Builds canvas area paths to fill under lines */
  _createAreas(linesMeshed) {
    const areas = [];
    for (let i = 1; i < linesMeshed.length; i++) {
      const areaPath = new Path2D(linesMeshed[i - 1].path);
      areaPath.lineTo(linesMeshed[i].first.x, linesMeshed[i].first.y);
      areaPath.addPath(linesMeshed[i].path);
      areaPath.lineTo(linesMeshed[i - 1].first.x, linesMeshed[i - 1].first.y);
      areaPath.closePath();
      areas.push(areaPath);
    }
    return areas;
  }
}
