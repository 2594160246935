"use strict";
import { defaultAbiCoder, Interface } from "@ethersproject/abi";
import { isAddress } from "@ethersproject/address";
import { BigNumber } from "@ethersproject/bignumber";
import { toUtf8String, Utf8ErrorFuncs, Utf8ErrorReason } from "@ethersproject/strings";
import UniJSON from "@uniswap/governance/build/Uni.json";
import { CurrencyAmount } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { GOVERNANCE_PROXY_ADDRESSES, RB_REGISTRY_ADDRESSES, STAKING_PROXY_ADDRESSES } from "constants/addresses";
import { LATEST_GOVERNOR_INDEX } from "constants/governance";
import { ZERO_ADDRESS } from "constants/misc";
import {
  BRAVO_START_BLOCK,
  MOONBEAN_START_BLOCK,
  ONE_BIP_START_BLOCK,
  POLYGON_START_BLOCK,
  UNISWAP_GRANTS_START_BLOCK
} from "constants/proposals";
import { POLYGON_PROPOSAL_TITLE } from "constants/proposals/polygon_proposal_title";
import { UNISWAP_GRANTS_PROPOSAL_DESCRIPTION } from "constants/proposals/uniswap_grants_proposal_description";
import { GRG, UNI } from "constants/tokens";
import { useAccount } from "hooks/useAccount";
import { useEthersWeb3Provider } from "hooks/useEthersProvider";
import { useContract } from "hooks/useContract";
import { useSingleCallResult, useSingleContractMultipleData } from "lib/hooks/multicall";
import useBlockNumber from "lib/hooks/useBlockNumber";
import { useCallback, useMemo } from "react";
import { VoteOption } from "state/governance/types";
import { useLogs } from "state/logs/hooks";
import { useTransactionAdder } from "state/transactions/hooks";
import { TransactionType } from "state/transactions/types";
import GOVERNANCE_RB_ABI from "uniswap/src/abis/governance.json";
import POOL_EXTENDED_ABI from "uniswap/src/abis/pool-extended.json";
import RB_REGISTRY_ABI from "uniswap/src/abis/rb-registry.json";
import STAKING_ABI from "uniswap/src/abis/staking-impl.json";
import STAKING_PROXY_ABI from "uniswap/src/abis/staking-proxy.json";
import { t } from "uniswap/src/i18n";
import { UniverseChainId } from "uniswap/src/types/chains";
import { calculateGasMargin } from "utils/calculateGasMargin";
function useGovernanceProxyContract() {
  const { chainId } = useAccount();
  return useContract(
    chainId ? GOVERNANCE_PROXY_ADDRESSES[chainId] : void 0,
    GOVERNANCE_RB_ABI,
    true
  );
}
const useLatestGovernanceContract = useGovernanceProxyContract;
function useUniContract() {
  const { chainId } = useAccount();
  const uniAddress = useMemo(() => chainId ? UNI[chainId]?.address : void 0, [chainId]);
  return useContract(uniAddress, UniJSON.abi, true);
}
function useRegistryContract() {
  const { chainId } = useAccount();
  return useContract(
    chainId ? RB_REGISTRY_ADDRESSES[chainId] : void 0,
    RB_REGISTRY_ABI,
    true
  );
}
export function useStakingContract() {
  const { chainId } = useAccount();
  return useContract(
    chainId ? STAKING_PROXY_ADDRESSES[chainId] : void 0,
    STAKING_ABI,
    true
  );
}
export function useStakingProxyContract() {
  const { chainId } = useAccount();
  return useContract(
    chainId ? STAKING_PROXY_ADDRESSES[chainId] : void 0,
    STAKING_PROXY_ABI,
    true
  );
}
export function usePoolExtendedContract(poolAddress) {
  return useContract(poolAddress, POOL_EXTENDED_ABI, true);
}
export var StakeStatus = /* @__PURE__ */ ((StakeStatus2) => {
  StakeStatus2[StakeStatus2["UNDELEGATED"] = 0] = "UNDELEGATED";
  StakeStatus2[StakeStatus2["DELEGATED"] = 1] = "DELEGATED";
  return StakeStatus2;
})(StakeStatus || {});
export var ProposalState = /* @__PURE__ */ ((ProposalState2) => {
  ProposalState2[ProposalState2["UNDETERMINED"] = -1] = "UNDETERMINED";
  ProposalState2[ProposalState2["PENDING"] = 0] = "PENDING";
  ProposalState2[ProposalState2["ACTIVE"] = 1] = "ACTIVE";
  ProposalState2[ProposalState2["CANCELED"] = 2] = "CANCELED";
  ProposalState2[ProposalState2["QUALIFIED"] = 3] = "QUALIFIED";
  ProposalState2[ProposalState2["DEFEATED"] = 4] = "DEFEATED";
  ProposalState2[ProposalState2["SUCCEEDED"] = 5] = "SUCCEEDED";
  ProposalState2[ProposalState2["QUEUED"] = 6] = "QUEUED";
  ProposalState2[ProposalState2["EXPIRED"] = 7] = "EXPIRED";
  ProposalState2[ProposalState2["EXECUTED"] = 8] = "EXECUTED";
  return ProposalState2;
})(ProposalState || {});
const GovernanceInterface = new Interface(GOVERNANCE_RB_ABI);
function useProposalCount(contract) {
  const { result } = useSingleCallResult(contract, "proposalCount");
  return result?.[0]?.toNumber();
}
const FOUR_BYTES_DIR = {
  "0x5ef2c7f0": "setSubnodeRecord(bytes32,bytes32,address,address,uint64)",
  "0x10f13a8c": "setText(bytes32,string,string)",
  "0xb4720477": "sendMessageToChild(address,bytes)",
  "0xa9059cbb": "transfer(address,uint256)",
  "0x095ea7b3": "approve(address,uint256)",
  "0x7b1837de": "fund(address,uint256)",
  "0x332f6465": "setAdapter(address,bool)",
  "0xd784d426": "setImplementation(address)",
  "0x83f94db7": "upgradeImplementation(address)",
  "0x42f1181e": "addAuthorizedAddress(address)",
  "0x37b006a6": "detachStakingContract",
  "0x66615d56": "attachStakingContract(address)",
  "0x70712939": "removeAuthorizedAddress(address)",
  "0xf2fde38b": "transferOwnership(address)",
  "0xc14b8e9c": "updateThresholds(uint256,uin256)",
  "0x3f4350a5": "upgradeStrategy(address)",
  "0xa91ee0dc": "setRegistry(address)",
  "0x7a9e5e4b": "setAuthority(address)",
  "0xb516e6e1": "setRigoblockDao(address)",
  "0xc91b0149": "setWhitelister(address,bool)",
  "0x13af4035": "setOwner(address)",
  "0x71013c10": "setFactory(address)",
  "0xcd29d473": "addMethod(bytes4,address)",
  "0xd9efcc1e": "removeMethod(bytes4,address)"
};
function useFormattedProposalCreatedLogs(contract, indices, fromBlock, toBlock) {
  const filter = useMemo(() => {
    const filter2 = contract?.filters?.ProposalCreated();
    if (!filter2) {
      return void 0;
    }
    return {
      ...filter2,
      fromBlock,
      toBlock
    };
  }, [contract, fromBlock, toBlock]);
  const useLogsResult = useLogs(filter);
  return useMemo(() => {
    return useLogsResult?.logs?.map((log) => {
      const parsed = GovernanceInterface.parseLog(log).args;
      return parsed;
    })?.filter((parsed) => indices.flat().some((i) => i === parsed.proposalId.toNumber()))?.map((parsed) => {
      let description;
      const proposer = parsed.proposer.toString();
      const proposalId = parsed.proposalId;
      const startBlock = parseInt(parsed.startBlockOrTime?.toString());
      try {
        description = parsed.description;
      } catch (error) {
        let onError = Utf8ErrorFuncs.replace;
        if (startBlock === BRAVO_START_BLOCK) {
          const U2018 = [226, 128, 152].toString();
          const U2026 = [226, 128, 166].toString();
          onError = (reason, offset, bytes, output) => {
            if (reason === Utf8ErrorReason.UNEXPECTED_CONTINUE) {
              const charCode = [bytes[offset], bytes[offset + 1], bytes[offset + 2]].reverse().toString();
              if (charCode === U2018) {
                output.push(8216);
                return 2;
              } else if (charCode === U2026) {
                output.push(8230);
                return 2;
              }
            }
            return Utf8ErrorFuncs.replace(reason, offset, bytes, output);
          };
        }
        description = JSON.parse(toUtf8String(error.error.value, onError)) || "";
      }
      if (startBlock === BRAVO_START_BLOCK || startBlock === ONE_BIP_START_BLOCK || startBlock === MOONBEAN_START_BLOCK) {
        description = description.replace(/ {2}/g, "\n").replace(/\d\. /g, "\n$&");
      }
      return {
        proposer,
        description,
        proposalId,
        details: parsed.actions.map((action) => {
          let calldata = action.data;
          const fourbyte = calldata.slice(0, 10);
          const sig = FOUR_BYTES_DIR[fourbyte] ?? "UNKNOWN()";
          if (!sig) {
            throw new Error("Missing four byte sig");
          }
          const [name, types] = sig.substring(0, sig.length - 1).split("(");
          calldata = `0x${calldata.slice(10)}`;
          const decoded = defaultAbiCoder.decode(types.split(","), calldata);
          return {
            target: action.target,
            functionSig: name,
            callData: decoded.join(", ")
          };
        })
      };
    });
  }, [indices, useLogsResult]);
}
function countToIndices(count, skip = 0) {
  return typeof count === "number" ? new Array(count - skip).fill(0).map((_, i) => [i + 1 + skip]) : [];
}
export function useAllProposalData() {
  const { chainId } = useAccount();
  const blockNumber = useBlockNumber();
  const gov = useGovernanceProxyContract();
  const proposalCount = useProposalCount(gov);
  const govProposalIndexes = useMemo(() => {
    return countToIndices(proposalCount);
  }, [proposalCount]);
  const proposals = useSingleContractMultipleData(gov, "getProposalById", govProposalIndexes);
  const proposalStates = useSingleContractMultipleData(gov, "getProposalState", govProposalIndexes);
  let govStartBlock;
  if (chainId === UniverseChainId.Mainnet) {
    govStartBlock = 16620590;
  } else if (chainId === UniverseChainId.Goerli) {
    govStartBlock = 8485377;
  } else if (chainId === UniverseChainId.ArbitrumOne) {
    govStartBlock = 60590354;
  } else if (chainId === UniverseChainId.Optimism) {
    govStartBlock = 74115128;
  } else if (chainId === UniverseChainId.Polygon) {
    govStartBlock = 39249858;
  } else if (chainId === UniverseChainId.Base) {
    govStartBlock = typeof blockNumber === "number" ? blockNumber - 4e3 : blockNumber;
  } else if (chainId === UniverseChainId.Bnb) {
    govStartBlock = typeof blockNumber === "number" ? blockNumber - 4e3 : blockNumber;
  }
  const formattedLogsV1 = useFormattedProposalCreatedLogs(gov, govProposalIndexes, govStartBlock);
  const grg = useMemo(() => chainId ? GRG[chainId] : void 0, [chainId]);
  return useMemo(() => {
    const proposalsCallData = [...proposals];
    const proposalStatesCallData = [...proposalStates];
    const formattedLogs = [...formattedLogsV1 ?? []];
    if (!grg || proposalsCallData.some((p) => p.loading) || proposalStatesCallData.some((p) => p.loading) || gov && !formattedLogs) {
      return { data: [], loading: true };
    }
    return {
      data: proposalsCallData.map((proposal, i) => {
        const startBlock = parseInt(proposal?.result?.proposalWrapper?.proposal?.startBlockOrTime?.toString());
        let description = formattedLogs[i]?.description ?? "";
        if (startBlock === UNISWAP_GRANTS_START_BLOCK) {
          description = UNISWAP_GRANTS_PROPOSAL_DESCRIPTION;
        }
        let title = description?.split(/#+\s|\n/g)[1];
        if (startBlock === POLYGON_START_BLOCK) {
          title = POLYGON_PROPOSAL_TITLE;
        }
        const details = proposal?.result?.proposalWrapper?.proposedAction.map((action) => {
          let calldata = action.data;
          const fourbyte = calldata.slice(0, 10);
          const sig = FOUR_BYTES_DIR[fourbyte] ?? "UNKNOWN()";
          if (!sig) {
            throw new Error("Missing four byte sig");
          }
          const [name, types] = sig.substring(0, sig.length - 1).split("(");
          calldata = `0x${calldata.slice(10)}`;
          const decoded = types ? defaultAbiCoder.decode(types.split(","), calldata) : [];
          return {
            target: action.target,
            functionSig: name,
            callData: decoded.join(", ")
          };
        });
        return {
          id: (i + 1).toString(),
          //formattedLogs[i]?.proposalId?.toString(),
          title: title ?? t("common.untitled"),
          description: description ?? t("common.noDescription"),
          proposer: formattedLogs[i]?.proposer,
          //proposal?.result?.proposer,
          status: proposalStatesCallData[i]?.result?.[0] ?? -1 /* UNDETERMINED */,
          forCount: CurrencyAmount.fromRawAmount(grg, proposal?.result?.proposalWrapper?.proposal?.votesFor),
          againstCount: CurrencyAmount.fromRawAmount(grg, proposal?.result?.proposalWrapper?.proposal?.votesAgainst),
          startBlock,
          endBlock: parseInt(proposal?.result?.proposalWrapper?.proposal?.endBlockOrTime?.toString()),
          eta: BigNumber.from(0),
          //proposal?.result?.eta,
          details,
          //: formattedLogs[i]?.details,
          governorIndex: 1
        };
      }),
      loading: false
    };
  }, [formattedLogsV1, gov, proposalStates, proposals, grg]);
}
export function useProposalData(governorIndex, id) {
  const { data } = useAllProposalData();
  return data.filter((p) => p.governorIndex === governorIndex)?.find((p) => p.id === id);
}
export function useQuorum(governorIndex) {
  const latestGovernanceContract = useLatestGovernanceContract();
  const govParams = useSingleCallResult(latestGovernanceContract, "governanceParameters")?.result?.[0];
  const quorumVotes = govParams?.params?.quorumThreshold;
  const { chainId } = useAccount();
  const grg = useMemo(() => chainId ? GRG[chainId] : void 0, [chainId]);
  if (!latestGovernanceContract || !quorumVotes || //chainId !== UniverseChainId.Mainnet ||
  !grg || governorIndex !== LATEST_GOVERNOR_INDEX) {
    return void 0;
  }
  return CurrencyAmount.fromRawAmount(grg, quorumVotes);
}
export function useUserDelegatee() {
  const account = useAccount();
  const uniContract = useUniContract();
  const { result } = useSingleCallResult(uniContract, "delegates", [account.address]);
  return result?.[0] ?? void 0;
}
export function useUserVotes() {
  const account = useAccount();
  const governance = useGovernanceProxyContract();
  const { result, loading } = useSingleCallResult(governance, "getVotingPower", [account.address]);
  return useMemo(() => {
    const grg = account.chainId ? GRG[account.chainId] : void 0;
    return { loading, votes: grg && result ? CurrencyAmount.fromRawAmount(grg, result?.[0]) : void 0 };
  }, [account.chainId, loading, result]);
}
export function useUserVotesAsOfBlock(block) {
  const account = useAccount();
  const uniContract = useUniContract();
  const uni = useMemo(() => account.chainId ? UNI[account.chainId] : void 0, [account.chainId]);
  const votes = useSingleCallResult(uniContract, "getPriorVotes", [account.address, block ?? void 0])?.result?.[0];
  return votes && uni ? CurrencyAmount.fromRawAmount(uni, votes) : void 0;
}
export function usePoolIdByAddress(pool) {
  const registryContract = useRegistryContract();
  const poolId = useSingleCallResult(registryContract ?? void 0, "getPoolIdFromAddress", [pool ?? void 0])?.result?.[0];
  const stakingContract = useStakingContract();
  const stakingPool = useSingleCallResult(stakingContract ?? void 0, "getStakingPool", [poolId])?.result?.[0];
  const stakingPoolExists = stakingPool !== void 0 ? stakingPool?.operator !== ZERO_ADDRESS : false;
  if (!poolId) {
    return { stakingPoolExists };
  } else {
    return { poolId, stakingPoolExists };
  }
}
export function useStakeBalance(poolId, owner) {
  const account = useAccount();
  const grg = account.chainId ? GRG[account.chainId] : void 0;
  const stakingContract = useStakingContract();
  const stake = useSingleCallResult(stakingContract ?? void 0, "getStakeDelegatedToPoolByOwner", [
    owner ?? account.address,
    poolId ?? void 0
  ])?.result?.[0];
  return stake && grg ? CurrencyAmount.fromRawAmount(grg, stake.nextEpochBalance) : void 0;
}
export function useDelegateCallback() {
  const account = useAccount();
  const { provider } = useWeb3React();
  const addTransaction = useTransactionAdder();
  const stakingContract = useStakingContract();
  const stakingProxy = useStakingProxyContract();
  return useCallback(
    (stakeData) => {
      if (!provider || !account.chainId || !account.address || !stakeData || !isAddress(stakeData.pool ?? "")) {
        return void 0;
      }
      const createPoolCall = stakingContract?.interface.encodeFunctionData("createStakingPool", [stakeData.pool]);
      const stakeCall = stakingContract?.interface.encodeFunctionData("stake", [stakeData.amount]);
      const fromInfo = { status: 0 /* UNDELEGATED */, poolId: stakeData.poolId };
      const toInfo = { status: 1 /* DELEGATED */, poolId: stakeData.poolId };
      const moveStakeCall = stakingContract?.interface.encodeFunctionData("moveStake", [
        fromInfo,
        toInfo,
        stakeData.amount
      ]);
      const delegatee = stakeData.pool;
      if (!delegatee) {
        return;
      }
      const args = !stakeData.stakingPoolExists ? [[createPoolCall, stakeCall, moveStakeCall]] : [[stakeCall, moveStakeCall]];
      if (!stakingProxy) {
        throw new Error("No Staking Contract!");
      }
      return stakingProxy.estimateGas.batchExecute(...args, {}).then((estimatedGasLimit) => {
        return stakingProxy.batchExecute(...args, { value: null, gasLimit: calculateGasMargin(estimatedGasLimit) }).then((response) => {
          addTransaction(response, {
            type: TransactionType.DELEGATE,
            delegatee
          });
          return response.hash;
        });
      });
    },
    [account.address, addTransaction, account.chainId, provider, stakingContract, stakingProxy]
  );
}
export function useDelegatePoolCallback() {
  const account = useAccount();
  const provider = useEthersWeb3Provider();
  const addTransaction = useTransactionAdder();
  return useCallback(
    (stakeData) => {
      if (!provider || !account?.chainId || !account.address || !stakeData || !isAddress(stakeData.pool ?? "")) {
        return void 0;
      }
      const delegatee = stakeData.pool;
      const poolInstance = stakeData.poolContract ?? void 0;
      if (!delegatee) {
        return;
      }
      const args = [stakeData.amount];
      if (!poolInstance) {
        throw new Error("No Pool Contract!");
      }
      return poolInstance.estimateGas.stake(...args, {}).then((estimatedGasLimit) => {
        return poolInstance.stake(...args, { value: null, gasLimit: calculateGasMargin(estimatedGasLimit) }).then((response) => {
          addTransaction(response, {
            type: TransactionType.DELEGATE,
            delegatee
          });
          return response.hash;
        });
      });
    },
    [account.address, addTransaction, account.chainId, provider]
  );
}
export function useMoveStakeCallback() {
  const account = useAccount();
  const { provider } = useWeb3React();
  const addTransaction = useTransactionAdder();
  const stakingContract = useStakingContract();
  const stakingProxy = useStakingProxyContract();
  return useCallback(
    (stakeData) => {
      if (!provider || !account.chainId || !account.address || !stakeData || !stakeData.fromPoolId || !isAddress(stakeData.pool ?? "")) {
        return void 0;
      }
      const createPoolCall = stakingContract?.interface.encodeFunctionData("createStakingPool", [stakeData.pool]);
      const deactivateFromInfo = { status: 1 /* DELEGATED */, poolId: stakeData.fromPoolId };
      const deactivateToInfo = { status: 0 /* UNDELEGATED */, poolId: stakeData.fromPoolId };
      const deactivateCall = stakingContract?.interface.encodeFunctionData("moveStake", [
        deactivateFromInfo,
        deactivateToInfo,
        stakeData.amount
      ]);
      const activateFromInfo = { status: 0 /* UNDELEGATED */, poolId: stakeData.poolId };
      const activateToInfo = { status: 1 /* DELEGATED */, poolId: stakeData.poolId };
      const activateCall = stakingContract?.interface.encodeFunctionData("moveStake", [
        activateFromInfo,
        activateToInfo,
        stakeData.amount
      ]);
      const delegatee = stakeData.pool;
      if (!delegatee) {
        return;
      }
      const args = !stakeData.stakingPoolExists ? stakeData.fromPoolId !== stakeData.poolId ? [[createPoolCall, deactivateCall, activateCall]] : [[createPoolCall, activateCall]] : stakeData.fromPoolId !== stakeData.poolId ? [[deactivateCall, activateCall]] : [[activateCall]];
      if (!stakingProxy) {
        throw new Error("No Staking Contract!");
      }
      return stakingProxy.estimateGas.batchExecute(...args, {}).then((estimatedGasLimit) => {
        return stakingProxy.batchExecute(...args, { value: null, gasLimit: calculateGasMargin(estimatedGasLimit) }).then((response) => {
          addTransaction(response, {
            type: TransactionType.DELEGATE,
            delegatee
          });
          return response.hash;
        });
      });
    },
    [account.address, addTransaction, account.chainId, provider, stakingContract, stakingProxy]
  );
}
export function useDeactivateStakeCallback() {
  const account = useAccount();
  const { provider } = useWeb3React();
  const addTransaction = useTransactionAdder();
  const stakingContract = useStakingContract();
  const stakingProxy = useStakingProxyContract();
  return useCallback(
    (stakeData) => {
      if (!provider || !account.chainId || !account.address || !stakeData || !isAddress(stakeData.pool ?? "")) {
        return void 0;
      }
      const deactivateFromInfo = { status: 1 /* DELEGATED */, poolId: stakeData.poolId };
      const deactivateToInfo = { status: 0 /* UNDELEGATED */, poolId: stakeData.poolId };
      const deactivateCall = stakingContract?.interface.encodeFunctionData("moveStake", [
        deactivateFromInfo,
        deactivateToInfo,
        stakeData.amount
      ]);
      const delegatee = stakeData.pool;
      const poolInstance = stakeData.poolContract ?? void 0;
      if (!delegatee) {
        return;
      }
      const args = stakeData.isPoolMoving ? [stakeData.amount] : [[deactivateCall]];
      if (!stakingProxy) {
        throw new Error("No Staking Contract!");
      }
      if (stakeData.isPoolMoving && !poolInstance) {
        throw new Error("No Pool Contract!");
      }
      if (stakeData.isPoolMoving && poolInstance) {
        return poolInstance.estimateGas.undelegateStake(...args, {}).then((estimatedGasLimit) => {
          return poolInstance.undelegateStake(...args, { value: null, gasLimit: calculateGasMargin(estimatedGasLimit) }).then((response) => {
            addTransaction(response, {
              type: TransactionType.DELEGATE,
              delegatee
            });
            return response.hash;
          });
        });
      }
      return stakingProxy.estimateGas.batchExecute(...args, {}).then((estimatedGasLimit) => {
        return stakingProxy.batchExecute(...args, { value: null, gasLimit: calculateGasMargin(estimatedGasLimit) }).then((response) => {
          addTransaction(response, {
            type: TransactionType.DELEGATE,
            delegatee
          });
          return response.hash;
        });
      });
    },
    [account.address, addTransaction, account.chainId, provider, stakingContract, stakingProxy]
  );
}
export function useVoteCallback() {
  const account = useAccount();
  const latestGovernanceContract = useLatestGovernanceContract();
  const addTransaction = useTransactionAdder();
  return useCallback(
    (proposalId, voteOption) => {
      if (!account.address || !latestGovernanceContract || !proposalId || !account.chainId) {
        return;
      }
      const args = [proposalId, voteOption === VoteOption.For ? 0 : voteOption === VoteOption.Against ? 1 : 2];
      return latestGovernanceContract.estimateGas.castVote(...args, {}).then((estimatedGasLimit) => {
        return latestGovernanceContract.castVote(...args, { value: null, gasLimit: calculateGasMargin(estimatedGasLimit) }).then((response) => {
          addTransaction(response, {
            type: TransactionType.VOTE,
            decision: voteOption,
            governorAddress: latestGovernanceContract.address,
            proposalId: parseInt(proposalId),
            reason: ""
          });
          return response.hash;
        });
      });
    },
    [account.address, addTransaction, latestGovernanceContract, account.chainId]
  );
}
export function useQueueCallback() {
  const account = useAccount();
  const latestGovernanceContract = useLatestGovernanceContract();
  const addTransaction = useTransactionAdder();
  return useCallback(
    (proposalId) => {
      if (!account.address || !latestGovernanceContract || !proposalId || !account.chainId) {
        return;
      }
      const args = [proposalId];
      return latestGovernanceContract.estimateGas.queue(...args, {}).then((estimatedGasLimit) => {
        return latestGovernanceContract.queue(...args, { value: null, gasLimit: calculateGasMargin(estimatedGasLimit) }).then((response) => {
          addTransaction(response, {
            type: TransactionType.QUEUE,
            governorAddress: latestGovernanceContract.address,
            proposalId: parseInt(proposalId)
          });
          return response.hash;
        });
      });
    },
    [account.address, addTransaction, latestGovernanceContract, account.chainId]
  );
}
export function useExecuteCallback() {
  const account = useAccount();
  const latestGovernanceContract = useLatestGovernanceContract();
  const addTransaction = useTransactionAdder();
  return useCallback(
    (proposalId) => {
      if (!account.address || !latestGovernanceContract || !proposalId || !account.chainId) {
        return;
      }
      const args = [proposalId];
      return latestGovernanceContract.estimateGas.execute(...args, {}).then((estimatedGasLimit) => {
        return latestGovernanceContract.execute(...args, { value: null, gasLimit: calculateGasMargin(estimatedGasLimit) }).then((response) => {
          addTransaction(response, {
            type: TransactionType.EXECUTE,
            governorAddress: latestGovernanceContract.address,
            proposalId: parseInt(proposalId)
          });
          return response.hash;
        });
      });
    },
    [account.address, addTransaction, latestGovernanceContract, account.chainId]
  );
}
export function useCreateProposalCallback() {
  const account = useAccount();
  const latestGovernanceContract = useLatestGovernanceContract();
  const addTransaction = useTransactionAdder();
  return useCallback(
    (createProposalData) => {
      if (!account.address || !latestGovernanceContract || !createProposalData || !account.chainId) {
        return void 0;
      }
      const args = [
        createProposalData.actions,
        //createProposalData.values,
        //createProposalData.signatures,
        //createProposalData.calldatas,
        createProposalData.description
      ];
      return latestGovernanceContract.estimateGas.propose(...args).then((estimatedGasLimit) => {
        return latestGovernanceContract.propose(...args, { gasLimit: calculateGasMargin(estimatedGasLimit) }).then((response) => {
          addTransaction(response, {
            type: TransactionType.SUBMIT_PROPOSAL
          });
          return response.hash;
        });
      });
    },
    [account.address, addTransaction, latestGovernanceContract, account.chainId]
  );
}
export function useProposalThreshold() {
  const { chainId } = useAccount();
  const latestGovernanceContract = useLatestGovernanceContract();
  const res = useSingleCallResult(latestGovernanceContract, "governanceParameters");
  const grg = useMemo(() => chainId ? GRG[chainId] : void 0, [chainId]);
  if (res?.result?.[0].params?.quorumThreshold && grg) {
    return CurrencyAmount.fromRawAmount(grg, res.result[0].params.proposalThreshold);
  }
  return void 0;
}
